@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    width: 100%;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.main-sec .container {
    width: 100%;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.main-sec .card-container {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
    gap:20px
}

.card-container .no-properties-found {
    width: 100%;
}

.main-sec .card-container .card {
    width: calc(100% / 3 - 40px / 3);
    margin: 0;
    position: relative;
    overflow: hidden;
}


.main-sec .card-container .card .card-image {
    position: relative;
    padding-top: 140%;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
}

.main-sec .card-container .card a.view-more-btn {
    display: block;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

.main-sec .card-container .card a.view-more-btn .card-category {
    display: block;
    font-size: 15px;
    line-height: 20px;
    font-family: "Montserrat", Sans-serif;
    color: #fff;
    position: absolute;
    top: 20px;
    left: 0px;
    background-color: #000;
    max-width: 200px;
    padding: 10px 15px 10px 20px;
}

.main-sec .card-container .card a.view-more-btn .card-price {
    position: absolute;
    bottom: 0px;
    left: 0px;
    color: #fff;
    width: 100%;
    padding: 15px 30px;
    background-color: rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 15px;
    color: #fff;
}

.main-sec .card-container .card a.view-more-btn:hover .card-price {
    bottom: -100%;
}

.main-sec .card-container .card a.view-more-btn .card-details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    text-align: center;
    padding: 125px 30px 30px;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    color: #fff;
}

.main-sec .card-container .card a.view-more-btn:hover .card-details {
    opacity: 1;
    visibility: visible;
}

.main-sec .card-container .card a.view-more-btn .card-details h2 {
    font-family: "Instrument Sans", sans-serif;
    color: #fff;
    font-weight: normal;
    font-size: 20px;
    margin: 0 0 10px;
}

.main-sec .card-container .card a.view-more-btn .card-details p {
    display: block;
    color: #fff;
    font-size: 16px;
    font-family: "Instrument Sans", sans-serif;
    line-height: 24px;
    margin-bottom: 3px;
}

.main-sec .card-container .card a.view-more-btn .card-details p.see-more-btn {
    text-decoration: none;
    color: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 25px;
    font-weight: bold;
    border-bottom: solid 2px #fff;
}

/*------header css-------*/
/* Initially hide the submenu */
.elementskit-submenu-panel {
    /* Delay visibility hiding */
    background-color: #fff;
    min-width: 150px;
    width: 100%;
    border: 0;
    padding: 5px 0;
    padding: 5px 0 !important;
}

@media (min-width: 1199px) {

.elementskit-submenu-panel {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0s 0.3s;
    display: block !important;
        position: absolute;
    top: 25px;
}

/* When the toggle button is hovered, show the submenu */
.header-menu-sec ul li:hover .elementskit-submenu-panel {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease, visibility 0s 0s;
    /* No delay on showing */
}

}

.header-menu-sec ul li {
    position: relative;
}

.header-right ul li{
    position: relative;
}

.header-right ul li.menu-item ul {
    right: 0;
    left: auto;
}

.header-right ul li.menu-item span {
    color: #fff;
    cursor: pointer;
    display: inline-block;

}

.header-right  ul li.menu-item span::after, .header-right  ul li ul li a::after  {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #A89A80;
    width: 0;
    height: 1px;
    transition: .3s all;
}

.header-right ul li:hover ul {
    visibility: visible;
    opacity: 1;
}

.header-right ul li.menu-item span:hover,
.elementskit-submenu-panel li.menu-item.active span {
    color: #A89A80 !important;
}

.header-right ul li.menu-item span:hover::after, .header-right  ul li ul li a:hover::after {
    width: 100%;
}

.elementskit-submenu-panel a {
    background-color: #fff !important;
    color: #000 !important;
    font-size: 13px !important;
    font-weight: 500;
    padding: 8px 8px 8px 8px;
    text-align: left;
    display: block !important;
}

.elementskit-submenu-panel a:hover {
    color: #A89A80 !important;
}

.elementskit-submenu-panel li.active a {
    color: #A89A80 !important;
}

/*.elementskit-submenu-panel li.active a::after{
    border: 0;
    width: 0;
}*/
.elementskit-submenu-panel li {
    text-align: left;
    margin: 0 !important;
    padding: 0 !important;
    position: relative;
}

.header-menu-sec ul li a:hover::after {
    width: 100%;
}

.header-menu-sec ul.main-menu>li.active>a::after {
    width: 100%;
}

.elementskit-submenu-panel li.active {
    color: #A89A80 !important;
}

.header-menu-sec ul li a::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #A89A80;
    width: 0;
    height: 1px;
    transition: .3s all;
}

.header-menu-sec ul li a {
    position: relative;
    display: inline-block;
    transition: .3s all;
}

.header-menu-sec ul li a:hover {
    color: #A89A80;
}

.header-menu-sec ul li.active a {
    color: #A89A80;
}


.banner-header {
    background: url(images/listings-bg.jpg) no-repeat center center;
    background-size: cover;
    padding: 13% 20px;
    text-align: center;
    min-height: 550px;
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
}

.banner-header:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: #000;
    content: "";
    z-index: 1;
}

.banner-header h1 {
    color: #fff;
    text-align: center;
    max-width: 1110px;
    margin: 0 auto;
    font-family:Mont,Sans-serif;
    font-weight: 500;
    position: relative;
    z-index: 1;
}

.price-text {
    color: #fff;
    line-height: normal;
    font-size: 40px;
    text-align: center;
    margin-top: 30px;
}

.header-section h2 {
    width: 100%;
    text-align: left;
    margin: 0 0 15px;
}

.header-section {
    padding: 0 0 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.single-properties-sec {
    padding-top: 50px;
    text-align: left;
    font-family: "Instrument Sans", sans-serif;
}

.single-properties-sec ul {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.single-properties-sec li {
    width: calc(100%/3);
    border-bottom: 1px solid #EEEEEE;
    border-right: 1px solid #EEEEEE;
    padding: 20px 23px 21px;
}

.single-properties-sec li:nth-child(3n+1) {
    border-left: 1px solid #EEEEEE;
}

.single-properties-sec li:nth-child(1),
.single-properties-sec li:nth-child(2),
.single-properties-sec li:nth-child(3) {
    border-top: 1px solid #EEEEEE;
}

.single-properties-sec h6 {
    color: #9f9f9f;
    font-size: 22px;
    line-height: 28.6px;
    font-weight: 400;
    margin: 0 0 5px;
}

.single-properties-sec p {
    font-size: 17px;
    line-height: 21.94px;
    color: #000;
    font-weight: 500;
    font-family: "Instrument Sans", sans-serif;
}

.image-gallery-sec img {
    width: 100%;
    vertical-align: top;
}

img.people-image {
    width: 100%;
}

.image-gallery-sec ul {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start
}

.image-gallery-sec li {
    width: calc(100%/3 - 30px/3);
    margin-bottom: 15px;
    margin-left: 15px;
    overflow: hidden;
}

.image-gallery-sec li:nth-child(3n+1) {
    margin-left: 0;
}

.image-gallery-sec {
    padding: 86px 0 62px;
}

.image-gallery-sec li img {
    transform: scale(1.4);
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    height: 100%;
    object-fit: cover;
}

.property-content {
    padding: 20px 0 0;
    text-align: left;
    font-family: "Instrument Sans", sans-serif;
}

.property-content h2 {
    margin: 0 0 15px;
    font-family: "Instrument Sans", Sans-serif;
    font-size: 34px;
    font-weight: 400;
}

.property-content p {
    margin: 0 0 10px;
    display: flex;
    align-items: center;
    font-family: "Instrument Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
}

.property-content img.emoji {
    width: 21px;
    margin-right: 7px;
    font-size: 0;
}

.map-sec iframe {
    width: 100%;
    height: 550px;
}

.properties-head-sec {
    padding: 32px 0px 0;
}

.properties-head-sec ul {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
}

.properties-head-sec li {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    min-width: 227px;
    position: relative;
}

.properties-head-sec li:nth-child(2) {
    width: calc(100% - 454px);
    justify-content: center;
}

.properties-head-sec li.info_form {
    justify-content: flex-end;
}

.properties-head-sec li.info_form {
    justify-content: flex-start !important;;
}

.properties-head-sec li a:not(.reques) {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    font-family: "Instrument Sans", sans-serif;
    color: #000;
    text-decoration: none;
}
.properties-head-sec li a.saved {
    color: #f00;
  }

.properties-head-sec i.fa-heart {
    font-size: 40px;
    margin-right: 13px;
}

div#login_check {
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    padding: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    border: solid 1px rgba(0, 0, 0, 0.05);
    text-align: center;
}

div#login_check p {
    font-size: 13px;
}

div#login_check p a {
    display: inline;
    text-decoration: underline;
}

.properties-head-sec li a.request {
    font-size: 15px;
    line-height: 16px;
    background: #000;
    border: 2px solid #000;
    color: #fff;
    padding: 14px 13px;
    max-width: 230px;
    display: block;
    width: 100%;
    text-decoration: none;
    text-align: center;
}

.properties-head-sec li a.request:nth-child(2) {
    margin-left: 16px;
    border: 2px solid;
    background: transparent;
    color: #000;
}

.properties-head-sec li a.request:hover {
    border: 2px solid;
    background: transparent;
    color: #000;
    transition: all .5s;
}

.properties-head-sec p:nth-child(2) {
    margin-right: 17px;
    font-size: 15px;
}

.properties-head-sec i.fa.fa-plus {
    font-size: 40px;
    margin-right: 13px;
}

div.save-as-pdf-pdfcrowd-button-wrap {
    display: block;
}

.save-as-pdf-pdfcrowd-reset,
.save-as-pdf-pdfcrowd-reset * {
    all: initial;
}

.properties-head-sec i.fa.fa-print {
    font-size: 40px;
    margin-right: 13px;
}

.properties-head-sec li a .save-as-pdf-pdfcrowd-button-wrap .save-as-pdf-pdfcrowd-button {
    margin: 0 !important;
    background: none !important;
    border: none !important;
    padding: 0 !important;
    font-size: inherit !important;
}

.properties-head-sec li a.request:nth-child(2):hover {
    border: 2px solid #000;
    background: #000;
    color: #ffffff;
    transition: all .5s;
}

.single-properties-sec ul.yield-annual {
    justify-content: center;
    text-align: center;
    margin-top: 15px;
}

.single-properties-sec ul.yield-annual li {
    border: none !important;
}

/* form-desgin */

.filter-sec .container {
    text-align: left;
}

.filter-sec .container>h4 {
    letter-spacing: 1px;
}

.filter-sec .container .wppb-user-forms ul li label span.wppb-required {
    color: red;
    margin-left: 5px;
}

.filter-sec .container .wppb-user-forms ul li label {
    letter-spacing: 1px;
    line-height: 24px;
    width: 100%;
    display: block;
    font-weight: 500;
    margin: 0px 0px 5px 0px;
}

.filter-sec .container .wppb-user-forms ul li input {
    font-family: "Instrument Sans", sans-serif;
    font-size: 14px !important;
    font-weight: 500;
    color: var(--e-global-color-primary);
    width: 100%;
    height: 46px;
    padding: 10px 15px 10px 15px;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #A7A7A7;
    border-radius: 0px 0px 0px 0px;
    vertical-align: baseline;
}

.filter-sec .container .wppb-user-forms ul li.wppb-form-field {
    margin-bottom: 14px;
}

.filter-sec .container .wppb-user-forms ul li.wppb-form-field h4 {
    letter-spacing: 1px;
}

.filter-sec .container .wppb-user-forms ul li.wppb-form-field input#sendCredentialsViaEmail,
.filter-sec .container .wppb-user-forms ul li.wppb-form-field input#rememberMe {
    width: 18px;
    height: 18px;
    float: none;
    margin-right: 10px;
    vertical-align: middle !important;
    margin-top: -3px !important;
}

.filter-sec .container .wppb-user-forms ul li label[for='rememberMe'] {
    display: flex;
    align-items: center;
}

.filter-sec .container .submit.button {
    background-color: #000000;
    font-family: "Instrument Sans", sans-serif;
    font-size: 14px !important;
    font-weight: 500;
    width: 100%;
    height: 46px;
    padding: 10px 15px 10px 15px;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #A7A7A7;
    border-radius: 0px 0px 0px 0px;
    color: #fff;
    cursor: pointer;
}

.filter-sec .container p.form-submit .submit.button:hover {
    background-color: transparent;
    color: #000;
}

.filter-sec .container .already-register {
    letter-spacing: 1px;
}

.filter-sec .container .already-register a {
    text-decoration: underline;
    cursor: pointer;
    font-family: "Instrument Sans", Sans-serif;
}

.filter-sec .container .already-register a.login-popup-form:hover {
    color: #A89A80;
}

.login-page .alert-popup {
    position: inherit;
    background-color: transparent;
    margin-top: 70px;
    margin-bottom: 0px;
    padding: 50px 15px 50px 15px;
}

.login-page .alert-popup .filter-sec>.container {
    background: #fff;
    max-width: 600px;
    border-radius: 10px;
    padding: 30px;
    position: relative;
    display: block;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 40px 40px 40px 40px;
    border: 0;
}

.login-page .alert-popup .filter-sec {
    overflow: visible;
}

/* form-desgin */

@media (max-width: 1024px) {
    .main-sec .card-container{
        gap:15px
    }
    .main-sec .card-container .card {
        width: calc(100% / 2 - 15px / 2);
    }


    .property-content h2 {
        font-size: 28px;
    }

    .elementskit-submenu-panel {
        top: 45px;
        padding-left: 10px !important;
    }
}

@media (max-width: 991px) {
    .properties-head-sec li {
        min-width: 0px;
    }

    .properties-head-sec li:nth-child(2) {
        width: calc(100% - 290px);
    }

    .properties-head-sec i.fa.fa-plus,
    .properties-head-sec i.fa.fa-print,
    .properties-head-sec i.fa-heart {
        font-size: 30px;
        margin-right: 10px;
    }

    .properties-head-sec li a.request {
        padding: 12px 10px;
        max-width: 180px;
    }

    .properties-head-sec p:nth-child(2) {
        margin-right: 0;
    }

    .properties-head-sec li a:not(.reques)+a {
        margin-left: 20px;
    }

    .filter-section .filter-form div.location-column,
    body .filter-section .filter-form>div {
        width: calc(100% / 3);
    }

    .filter-section .filter-form>div.condition-column {
        border-left: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.7);
    }

    .filter-section .filter-form>div.beds-column,
    .filter-section .filter-form>div.area-column {
        border-top: 1px solid rgba(0, 0, 0, 0.7);
    }

    .filter-section .filter-form {
        flex-wrap: wrap;
    }

    .price-text {
        font-size: 30px;
    }
}

@media (max-width: 767px) {
    .header-section p {
        width: 100%;
        text-align: left;
        margin: 0 0 5px;
    }

    .main-sec .card-container .card:nth-child(3n+1),
    .main-sec .card-container .card {
        width: 100%;
        margin: 0;
    }

    .main-sec .card-container .card:nth-child(2n+1) {
        margin-left: 0;
    }

    .single-properties-sec li {
        padding: 16px 19px 17px;
    }

    .single-properties-sec h6 {
        font-size: 19px;
        line-height: 27.6px;
    }

    .single-properties-sec p {
        font-size: 15px;
        line-height: 20.94px;
    }

    .single-properties-sec li {
        width: 50%;
    }

    .single-properties-sec li:nth-child(3) {
        border-top: none;
    }

    .single-properties-sec li:nth-child(3n+1) {
        border-left: none;
    }

    .single-properties-sec li:nth-child(2n+1) {
        border-left: 1px solid #EEEEEE;
    }

    .image-gallery-sec li:nth-child(3n+1),
    .image-gallery-sec li {
        width: calc(100%/2 - 15px/2);
        margin-bottom: 15px;
        margin-left: 15px;
    }

    .image-gallery-sec li:nth-child(2n+1) {
        margin-left: 0;
    }

    .map-sec iframe {
        width: 100%;
        height: 350px;
    }

    .properties-head-sec ul {
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
    }

    .properties-head-sec li:nth-child(1) {
        margin-right: 15px;
    }

    .properties-head-sec li:nth-child(2) {
        width: 100%;
        order: 3;
        padding: 15px 0 0;
        justify-content: center !important;
    }

    .properties-head-sec i.fa.fa-print,
    .properties-head-sec i.fa.fa-plus,
    .properties-head-sec i.fa-heart {
        font-size: 30px;
        margin-right: 10px;
    }

    .properties-head-sec li a.request {
        margin: 0 5px 10px !important;
        max-width: 170px;
    }

    .properties-head-sec p:nth-child(2) {
        margin-right: 0;
        font-size: 14px;
    }

    .login-page .alert-popup {
        margin-top: 66px;
        margin-bottom: 0px;
    }

    .login-page .alert-popup .filter-sec {
        overflow: visible;
        padding: 0;
    }

    .price-text {
        font-size: 29px;
    }

}

.filter-section .filter-form>div>div:nth-child(2) ul li {
    line-height: normal;
    padding: 5px 0;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.filter-section .filter-form>div>div:nth-child(2) ul li input[type="checkbox"] {
    margin: 0 8px 0 0 !important;
}

.filter-section .filter-form>div>div:nth-child(2) ul li>span {
    font-family: "Instrument Sans", sans-serif;
    font-size: 14px;
    line-height: 17px;
    width: calc(100% - 23px);
}


.filter-section .filter-form .location-column .visible-location {
    padding-left: 20px;
}

.filter-section .filter-form .location-column i.fa.fa-map-pin {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    right: auto;
    color: rgba(0, 0, 0, 0.7);
}
.filter-section .filter-form>div>div i{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 6px;
    color: rgba(0, 0, 0, 0.7);
}
.check-property {
    padding: 20px 0 0;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
}
.check-property .check-text {
    position: relative;
}
.check-property .check-text input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
}
.check-property .check-text label {
    display: inline-block;
    font-size: 16px;
    padding: 6px 0 6px 55px;
    position: relative;
}
.check-property .check-text label:before {
    width: 36px;
    height: 36px;
    border: solid 1px #000;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    box-sizing: border-box;
}
.check-property .check-text input:checked + label:before {
    background: url(images/chack-icon.png) no-repeat center center;
}
.check-property a.remove-property {
    font-size: 16px;
    color: #000 !important;
    text-decoration: none;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: flex-end;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
}
.check-property a.remove-property span {
    vertical-align: top;
    margin-left: 15px;
    width: 36px;
    height: 36px;
    border: solid 1px #000;
    box-sizing: border-box;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
}
.card-container .no-properties-found {
    width: 100%;
    text-align: center;
    padding: 10px 0 20px;
}
.my-list {
    text-align: center;
    width: 100%;
    margin: 0 0 35px;
}
.my-list a.request {
    width: auto;
    max-width: 100%;
    margin: 0 !important;
    font-size: 15px;
    line-height: 16px;
    border: 2px solid #000;
    padding: 14px 13px;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    background: transparent;
    color: #000;
    font-family: "Instrument Sans", sans-serif;
}
.my-list a.request:hover {
    background: #000;
    color: #fff;
}
.check-property a.remove-property img {
    vertical-align: top;
    display: block;
}
.main-sec .card-container ul.list-property {
    width: 100%;
}
.login-register-sec {
    width: 100%;
    text-align: center;
}
body .form-btn-sec input[type="button"],
.request-form input[type="submit"]{
    cursor: pointer;
}
.request-form  form > input[type="submit"]:hover,
body .form-btn-sec input[type="button"]:hover {
    color: #fff;
    background: #000;
    border-color: #000;
}
.success-message.green-msg{
    color: green;
    font-weight: bold;
}



@media only screen and (max-width: 600px) {

    .filter-section .filter-form div.location-column,
    body .filter-section .filter-form>div {
        width: calc(100% / 2);
    }

    body .filter-section .filter-form>div.price-column {
        border-left: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.7);
    }

    .filter-section .filter-form>div.condition-column {
        border-left: 1px solid rgba(0, 0, 0, 0.7);
    }

    .filter-section .filter-form>div.beds-column {
        border-left: 0;
    }

    .filter-section .filter-form>div.beds-column,
    .filter-section .filter-form>div.area-column {
        border-top: 1px solid rgba(0, 0, 0, 0.7);
    }
}

@media screen and (max-width: 567px) {

    .single-properties-sec p,
    .single-properties-sec li.elementor-icon-list-item span.elementor-icon-list-text span {
        font-size: 14px;
        line-height: 19.94px;
    }

    .single-properties-sec h6,
    .single-properties-sec li.elementor-icon-list-item span.elementor-icon-list-text {
        font-size: 18px;
        line-height: 25.6px;
    }

    .single-properties-sec li {
        padding: 14px 18px 14px;
    }

    .single-properties-sec li.elementor-icon-list-item {
        padding: 14px 18px 14px !important;
    }

    .image-gallery-sec {
        padding: 45px 0 27px;
    }

    .properties-head-sec {
        padding: 30px 0px 0;
    }
        .check-property .check-text label {
        padding: 3px 0 3px 45px;
    }
        .check-property a.remove-property span, .check-property .check-text label:before {
        width: 30px;
        height: 30px;
    }
        .check-property a.remove-property img {
        max-width: 16px;
    }

    .price-text {
        font-size: 22px;
        margin-top: 16px;
    }
}

@media screen and (max-width: 480px) {
    .single-properties-sec li {
        width: 100%;
        border-left: 1px solid #EEEEEE;
    }

    .single-properties-sec li:nth-child(2) {
        border-top: none;
    }

    .image-gallery-sec li:nth-child(3n+1),
    .image-gallery-sec li {
        width: 100%;
        margin-bottom: 15px;
        margin-left: 0;
    }

    .single-properties-sec li:nth-child(3n+1) {
        border-left: 1px solid #EEEEEE;
    }
}

/*Registration popUp*/

.alert-popup {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgb(0 0 0 / 22%);
    z-index: 9999999;
}

.alert-popup .filter-sec {
    padding: 40px 15px;
    height: 100%;
    overflow: auto;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
}

.alert-popup .filter-sec>.container {
    background: #fff;
    max-width: 554px;
    border: 2px solid #000000;
    border-radius: 10px;
    padding: 30px;
    position: relative;
    display: block;
}

.alert-popup h4 {
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    position: relative;
    padding-right: 40px;
    margin-bottom: 20px;
}

.alert-popup .filter-sec form.filterform {
    border: none;
}

.alert-popup .filter-sec form.filterform>div {
    width: 100% !important;
    margin-bottom: 20px !important;
    border: 1px solid rgba(0, 0, 0, 0.7) !important;
}

.alert-popup .filter-sec form.filterform>div>div {
    height: auto !important;
}

.alert-popup .filter-sec form.filterform input {
    height: 50px !important;
    padding: 10px 20px !important;
    font-weight: 500 !important;
    line-height: 17px;
}

.alert-popup .filter-sec form.filterform>div>div:nth-child(1) i.fa.fa-chevron-down {
    right: 20px;
}

.alert-popup .filter-sec form.filterform>div.spa-submit,
.alert-popup .filter-sec form.filterform>div.ari-submit,
.alert-popup .filter-sec form.filterform>div.aryi-submit,
.alert-popup .filter-sec form.filterform>div.rpa-submit {
    border: none !important;
    margin: 0px !important;
}

.alert-popup h4 span {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 3px;
}

.alert-popup .filter-sec form.filterform>div input[type="submit"] {
    width: 100%;
    font-weight: 400 !important;
    font-size: 16px;
    font-family: "Instrument Sans", sans-serif;
    cursor: pointer;
    border-width: 1px;
}
.alert-popup .filter-sec form.filterform>div.alert-spa-submit input[type="submit"] {
    background: #000;
    color: #fff;
    border-color: #000;
}
.alert-popup .filter-sec form.filterform>div.alert-spa-submit input[type="submit"]:hover {
    background: #fff;
    color: #000;
    border-color: #000;
}

.alert-popup .filter-sec form.filterform ul li input[type="checkbox"] {
    height: auto !important;
}

.alert-popup .filter-sec form.filterform>div>div:nth-child(2) {
    max-height: 200px;
}

.alert-popup .filter-sec form.filterform>div>div:nth-child(2) input:not([type="checkbox"]) {
    height: auto !important;
}

.alert-popup form.wppb-register-user label,
.alert-popup form#wppb-loginform label {
    width: 100%;
    display: block;
    font-weight: 500;
    margin: 0px 0px 5px 0px;
}

.alert-popup form.wppb-register-user input,
.alert-popup form#wppb-loginform input {
    font-family: "Instrument Sans", sans-serif;
    font-size: 14px !important;
    font-weight: 500;
    color: var(--e-global-color-primary);
    width: 100%;
    height: 46px;
    padding: 10px 15px 10px 15px;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #A7A7A7;
    border-radius: 0px 0px 0px 0px;
}

.alert-popup form.wppb-register-user h4,
.alert-popup form#wppb-loginform h4 {
    padding: 0px !important;
    margin: 0;
    font-weight: 500;
    font-size: inherit;
}

.alert-popup form.wppb-register-user input#send_credentials_via_email,
.alert-popup form#wppb-loginform input#rememberme {
    font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
    font-weight: var(--e-global-typography-secondary-font-weight);
    width: 18px;
    height: 18px;
}

.alert-popup form.wppb-register-user input.submit,
.alert-popup form#wppb-loginform input.button {
    color: #fff;
}

.alert-popup form.wppb-register-user input.submit:hover,
.alert-popup form#wppb-loginform input.button:hover {
    color: var(--e-global-color-primary);
}

.alert-popup .already-register {
    text-align: center;
    margin-top: 15px;
}

.alert-popup form.wppb-register-user span.wppb-form-error {
    display: none;
}

.alert-popup form.wppb-register-user .wppb-field-error input {
    border-color: #C00;
}

.alert-popup form#wppb-loginform .login-remember label {
    margin: 0;
}
.request-form {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999999999999;
    background: rgb(0 0 0 / 43%);
    padding: 15px;
    overflow: auto;
}
.request-form .inner-request-form {
    background: #fff;
    position: relative;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    padding: 40px 30px;
    border-radius: 5px;
    padding-top: 30px;
}
.request-info-popup-close, .request-viewing-popup-close, .share-popup-close {
    position: absolute;
    right: 15px;
    text-align: center;
    font-size: 24px;
    cursor: pointer;
    top: 24px;
}
.zcwf_title, .zcwf_lblLeft .wfrm_fld_dpNn {
    display: none;
}
.request-form input, .request-form textarea {
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 15px 10px 15px;
    width: 100%;
    min-height: 46px;
    color: #000000;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-bottom: 15px;
}
body .form-btn-sec {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
}
.request-form .form-btn-sec input[type="submit"] {
    padding-left: 10px !important;
    padding-right: 10px !important;
    background-color: #000;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    border-style: solid;
    border-width: 1px;
    border-color: #000;
    border-radius: 0px 0px 0px 0px;
    padding: 12px 10px;
    font-family: "Montserrat", Sans-serif;
}
body .form-btn-sec input {
    width: calc(50% - 15px / 2);
    margin-bottom: 0;
    cursor: pointer;
}
.request-form h3 {
    margin-bottom: 20px;
    font-size: 24px !important;
    font-weight: 400;
    font-family: "Instrument Sans", sans-serif;
}

.wpcf7-form-control.wpcf7-submit.has-spinner.btn {
    background-color: black;
    color: white;
    cursor: pointer;
}

.wpcf7-form-control.wpcf7-submit.has-spinner.btn:hover {
    border: 2px solid #000 !important;
    background: white !important;
    color: black !important;
    transition: all 0.5s !important;
}

.formsubmit.zcwf_button:hover {
    border: 2px solid #000 !important;
    background: white !important;
    color: black !important;
    transition: all 0.5s !important;
}
