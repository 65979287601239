@font-face {
    font-family: 'Compass';
    src: url('Compass.eot');
    src: url('Compass.eot?#iefix') format('embedded-opentype'),
        url('Compass.woff2') format('woff2'),
        url('Compass.woff') format('woff'),
        url('Compass.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Compass LT';
    src: url('CompassLight.eot');
    src: url('CompassLight.eot?#iefix') format('embedded-opentype'),
        url('CompassLight.woff2') format('woff2'),
        url('CompassLight.woff') format('woff'),
        url('CompassLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Compass';
    src: url('CompassBold.eot');
    src: url('CompassBold.eot?#iefix') format('embedded-opentype'),
        url('CompassBold.woff2') format('woff2'),
        url('CompassBold.woff') format('woff'),
        url('CompassBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

