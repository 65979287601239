@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


@font-face {
    font-family: 'Mont';
    src: url('fonts/mont/Mont-Bold.eot');
    src: url('fonts/mont/Mont-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts/mont/Mont-Bold.woff2') format('woff2'),
        url('fonts/mont/Mont-Bold.woff') format('woff'),
        url('fonts/mont/Mont-Bold.ttf') format('truetype'),
        url('fonts/mont/Mont-Bold.svg#Mont-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body {
    font-family: "Instrument Sans", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
    font-style: normal;
}

img,
iframe,
svg,
video {
    max-width: 100%;
    vertical-align: middle;
    height: auto;
}

h1 {
    /* font-family: "Instrument Sans", Sans-serif; */
    font-family: "Mont", Sans-serif;
    font-size: 58px;
    font-weight: 400;
    line-height: 1;
}

h2 {
    /* font-family: "Instrument Sans", Sans-serif; */
    font-family: "Mont", Sans-serif;
    font-size: 34px;
    font-weight: 400;
    line-height: 1;
}

h3 {
    /* font-family: "Instrument Sans", Sans-serif; */
    font-family: "Mont", Sans-serif;
    font-size: 32px;
    font-weight: 400;
}

h4 {
    /* font-family: "Instrument Sans", Sans-serif; */
    font-family: "Mont", Sans-serif;
    font-weight: 400;
}

h5 {
    /* font-family: "Instrument Sans", Sans-serif; */
    font-family: "Mont", Sans-serif;
    font-weight: 400;
}

h6 {
    /* font-family: "Instrument Sans", Sans-serif; */
    font-family: "Mont", Sans-serif;
    font-weight: 400;
}

p {
    margin-bottom: 20px;
    font-size: 16px;
}

p:last-child {
    margin-bottom: 0;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.btn {
    background-color: #000000;
    font-size: 16px;
    display: inline-block;
    line-height: 1;
    font-weight: 400;
    color: #FFFFFF;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #000000;
    border-radius: 0px 0px 0px 0px;
    padding: 15px 30px 15px 30px;
    text-align: center;
    box-shadow: none;
    text-decoration: none;
    transition: all .3s;
    font-family: "Instrument Sans", Sans-serif;
    min-width: 240px;
}

.btn:hover {
    background-color: #FFFFFF00;
    color: #000000;
}

.btn-2 {
    background-color: #FFFFFF00;
    color: #000000;
    font-size: 16px;
    display: inline-block;
    line-height: 1;
    font-weight: 400;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #000000;
    border-radius: 0px 0px 0px 0px;
    padding: 15px 30px 15px 30px;
    text-align: center;
    box-shadow: none;
    text-decoration: none;
    transition: all .3s;
    font-family: "Instrument Sans", Sans-serif;
    min-width: 240px;
}

.btn-2:hover {
    background-color: #000000;
    color: #FFFFFF;
}

.btn-3 {
    background-color: #FFFFFF00;
    color: #FFFFFF;
    font-size: 16px;
    display: inline-block;
    line-height: 1;
    font-weight: 400;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #FFFFFF;
    border-radius: 0px 0px 0px 0px;
    padding: 15px 30px 15px 30px;
    text-align: center;
    box-shadow: none;
    text-decoration: none;
    transition: all .3s;
    font-family: "Instrument Sans", Sans-serif;
    min-width: 240px;
}

.btn-3:hover {
    background-color: #FFFFFF;
    color: #000;
}



.container {
    max-width: 1392px;
    padding: 0 15px;
    margin: 0 auto;
}

.main-banner {
    width: 100%;
    height: 100vh;
    position: relative;
}

.detail-video .main-banner::before {
    display: none;
}

.main-banner::before {
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: #000;
    opacity: 0.5;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    z-index: 1;
}

.main-banner .bg-video-container {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
    z-index: 0;
    direction: ltr;
}

.main-banner .bg-video-container video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.banner-logo {
    position: relative;
    z-index: 99;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner-logo .logo-img {
    max-width: 300px;
    padding: 0px 0px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.about-main {
    margin-top: 70px;
    margin-bottom: 0px;
}

.about-main .about-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.about-main .about-row .left-col {
    width: 40%;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.about-main .about-row .left-col-img {
    margin-right: 49px;
}

.about-main .about-row .left-col-img img {
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
}

.about-main .about-row .right-col {
    width: 60%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-main .about-row .right-col .right-col-content {
    margin-left: 51px;
    width: 100%;
    height: auto;
    overflow: visible;
}


.about-main .about-row .right-col .right-col-content p>strong {
    text-decoration: underline;
}

.about-main .about-row .right-col .right-col-content ul {
    margin: 0px 0px 40px 0px;
    padding: 0px 0px 0px 30px;
}

.about-main .about-row .right-col .right-col-content ul li {
    display: inherit;
    margin: 0;
}

.about-main .about-row .right-col .right-col-content ul li span {
    display: inline;
    background-color: inherit;
    color: inherit;
    margin: 0;
}

.about-main .about-row .right-col .right-col-content .btn-row {
    display: flex;
    gap: 20px;
}

.about-main .about-row .right-col .right-col-content .btn-row a {
    margin: 15px 0px 0px 0px;
}

.partners-sec {
    margin-top: 100px;
    margin-bottom: 0;
}

.partner-title {
    text-align: center;
    padding-bottom: 20px;
}

.partner-title h3 {
    line-height: 1;
}

.partners-sec .container {
    padding: 10px 15px;
    max-width: 1410px;
}

.partners-sec .marquee {
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    overflow: hidden;
    margin: 20px 0
}

.partners-sec .marquee-content {
    display: flex;
    -webkit-animation: marquee 50s linear infinite running;
    -moz-animation: marquee 50s linear infinite running;
    -o-animation: marquee 50s linear infinite running;
    -ms-animation: marquee 50s linear infinite running;
    animation: marquee 50s linear infinite running;
}

.partners-sec .marquee-item {
    width: 250px;
    padding: 0px 10px;
    display: inline-flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    border-left: 1.5px solid rgb(203 195 195 / 35%);
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translate(-50%);
    }
}

.exclusive-listings {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 70px;
    margin-bottom: 0px;
    padding: 60px 10px 60px 10px;
    background-image: url(../images/div-4.png);
    position: relative;
}

.exclusive-listings .exclusive-title {
    text-align: center;
    padding-bottom: 20px;
}

.exclusive-listings .exclusive-title h2 {
    line-height: 1;
    color: #fff;
    font-weight: 500;
    /* font-family: "Montserrat", Sans-serif; */
    font-family: "Mont", Sans-serif;
}

.exclusive-listings .container {
    width: 100%;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 15px;
}

.exclusive-listings .list-property {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
    margin-top: 20px;
}

.exclusive-listings .list-property>li:nth-of-type(3n+1) {
    margin-left: 0;
}

.exclusive-listings .list-property>li {
    width: calc(100% / 3 - 40px / 3);
    margin: 0 0 20px 20px;
    position: relative;
    overflow: hidden;
}

.exclusive-listings .property-feat-image {
    position: relative;
    padding-top: 140%;
    overflow: hidden;
}

.exclusive-listings .property-feat-image img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.4);
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
}

.exclusive-listings .list-property>li .heading-title {
    display: block;
    font-size: 15px;
    line-height: 20px;
    font-family: "Montserrat", Sans-serif;
    color: #fff;
    position: absolute;
    top: 20px;
    left: 0px;
    background-color: #000;
    max-width: 200px;
    padding: 10px 15px 10px 20px;
}

.exclusive-listings .list-property>li .property-content-hover h4 {
    color: #fff;
    font-weight: normal;
    font-size: 20px;
    margin: 0 0 10px;
}

.exclusive-listings .list-property>li .property-content-hover>.see-more-prop {
    text-decoration: none;
    color: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 25px;
    font-weight: bold;
    border-bottom: solid 2px #fff;
}

.exclusive-listings .list-property>li .property-content-hover span {
    display: block;
    color: #fff;
    font-size: 16px;
}

.exclusive-listings .list-property>li .property-content-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    text-align: center;
    padding: 125px 30px 30px;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.exclusive-listings .list-property>li:hover .property-content-hover {
    opacity: 1;
    visibility: visible;
}

.exclusive-listings .list-property>li .property-content {
    position: absolute;
    bottom: 0px;
    left: 0px;
    color: #fff;
    width: 100%;
    padding: 15px 30px;
    background-color: rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
}

.exclusive-listings .list-property>li .property-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.exclusive-listings .list-property>li .property-content h6 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    color: #fff;
}

.exclusive-listings .list-property>li .property-content h6 {
    width: 100%;
}

.exclusive-listings .list-property>li:hover .property-content {
    bottom: -100%;
}

.exclusive-listings .btn-row {
    text-align: center;
    margin-top: 20px;
}

.testimonials-sec {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 50px 0px 50px 0px;
    background-color: #fff;
    background-image: url(../images/testimonials-bg.png);
}

.testimonials-sec .testimonials-main {
    max-width: 900px;
    margin: auto;
    margin-bottom: 40px;
    text-align: center;
    background-color: #fff;
    padding-top: 32px;
    padding-bottom: 12px;
}

.testimonials-sec .testimonials-main .testimonials-slider {
    padding: 0px 15px;
}

.testimonials-sec .testimonials-main .testimonials-slider .testimonials-title h4 {
    font-weight: 700;
    text-decoration: underline;
}

.testimonials-sec .testimonials-main .testimonials-slider .testimonials-des {
    font-size: 22px;
    line-height: 31px;
    padding-top: 30px;
    margin-bottom: 0;
}

.testimonials-sec .testimonials-main .testimonials-slider .item h3 {
    font-size: 17px !important;
    font-weight: 400;
    font-style: italic;
    padding-top: 30px;
}

.testimonials-sec .testimonials-main .testimonials-slider .owl-nav {
    max-width: 320px;
    position: relative;
    margin: 0 auto;
    margin-bottom: -25px;
    margin-top: 15px;
}

.testimonials-sec .testimonials-main .testimonials-slider .owl-nav button {
    position: absolute;
    left: 0px;
    bottom: -66px;
    height: auto;
    z-index: 10000;
    margin: 0;
    font-weight: 900;
    color: #000;
    text-align: start;
}

.testimonials-sec .testimonials-main .testimonials-slider .owl-nav button:hover {
    background-color: transparent;
    color: #000;
}

.testimonials-sec .testimonials-main .testimonials-slider .owl-nav .owl-next {
    right: 0;
    left: auto;
}

.testimonials-sec .testimonials-main .testimonials-slider .owl-dots {
    bottom: -67px;
    position: relative;
    z-index: -1;
    padding-bottom: 10px;
}

.testimonials-sec .testimonials-main .testimonials-slider .owl-dots .owl-dot {
    margin: 0px 10px;
    width: auto;
    height: auto;
    color: #000;
    counter-increment: step-counter;
    transition: .3s all;
    border-radius: 100%;
}

.testimonials-sec .testimonials-main .testimonials-slider .owl-dots .owl-dot:before {
    content: '0';
}

.testimonials-sec .testimonials-main .testimonials-slider .owl-dots .owl-dot:after {
    content: counter(step-counter);
}

.testimonials-sec .testimonials-main .testimonials-slider .owl-dots .owl-dot.active {
    color: #000;
    font-weight: 700;
}

.testimonials-sec .testimonials-main .testimonials-slider .owl-dots .owl-dot span {
    font-size: 0;
    margin: 0;
    display: none;
}

.testimonials-sec .testimonials-main .testimonials-slider .owl-nav .owl-prev i {
    font-weight: 900;
}

.latest-properties {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 40px 10px 40px 10px;
    background-image: url(../images/div-4.png);
    position: relative;
}

.latest-properties .latest-title {
    text-align: center;
    padding-bottom: 20px;
    margin: 10px 0 45px;
}

.latest-properties .latest-title h2 {
    line-height: 1;
    color: #fff;
    font-weight: 500;
    /* font-family: "Montserrat", Sans-serif; */
    font-family: "Mont", Sans-serif;
}

.latest-properties .container {
    width: 100%;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 15px;
}

.latest-properties .list-property {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
    margin-top: 20px;
}

.latest-properties .list-property>li:nth-of-type(3n+1) {
    margin-left: 0;
}

.latest-properties .list-property>li {
    width: calc(100% / 3 - 40px / 3);
    margin: 0 0 20px 20px;
    position: relative;
    overflow: hidden;
}

.latest-properties .property-feat-image {
    position: relative;
    padding-top: 140%;
    overflow: hidden;
}

.latest-properties .property-feat-image img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.4);
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
}

.latest-properties .list-property>li .heading-title {
    display: block;
    font-size: 15px;
    line-height: 20px;
    font-family: "Montserrat", Sans-serif;
    color: #fff;
    position: absolute;
    top: 20px;
    left: 0px;
    background-color: #000;
    max-width: 200px;
    padding: 10px 15px 10px 20px;
}

.latest-properties .list-property>li .property-content-hover h4 {
    color: #fff;
    font-weight: normal;
    font-size: 20px;
    margin: 0 0 10px;
}

.latest-properties .list-property>li .property-content-hover>.see-more-prop {
    text-decoration: none;
    color: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 25px;
    font-weight: bold;
    border-bottom: solid 2px #fff;
}

.latest-properties .list-property>li .property-content-hover span {
    display: block;
    color: #fff;
    font-size: 16px;
}

.latest-properties .list-property>li .property-content-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    text-align: center;
    padding: 125px 30px 30px;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.latest-properties .list-property>li:hover .property-content-hover {
    opacity: 1;
    visibility: visible;
}

.latest-properties .list-property>li .property-content {
    position: absolute;
    bottom: 0px;
    left: 0px;
    color: #fff;
    width: 100%;
    padding: 15px 30px;
    background-color: rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
}

.latest-properties .list-property>li .property-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.latest-properties .list-property>li .property-content h6 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    color: #fff;
}

.latest-properties .list-property>li .property-content h6 {
    width: 100%;
}

.latest-properties .list-property>li:hover .property-content {
    bottom: -100%;
}

.latest-properties .btn-row {
    text-align: center;
    margin-top: 20px;
}


.team-sec {
    margin-top: 60px;
    margin-bottom: 60px;
}

.team-sec .team-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.team-sec .team-row .team-left-col {
    width: 40%;
}

.team-sec .team-row .team-left-col .team-left-col-content {
    padding: 10px;
    margin-right: 30px;
}

.team-sec .team-row .team-left-col .team-left-col-content h2 {
    font-weight: 500;
    line-height: 1;
    margin-bottom: 20px;
}

.team-sec .team-row .team-right-col {
    width: 60%;
}

.team-sec .team-row .team-right-col .team-right-col-img {
    padding: 10px;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
}

.team-sec .team-row .team-right-col .team-right-col-img img {
    max-width: 639.7px;

}

.contact-main {
    background-color: #FFFFFF;
    background-image: url(../images/limassol-2.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.contact-main::before {
    content: '';
    background-color: #000;
    opacity: 0.2;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.contact-main .contact-row {
    max-width: 480px;
    min-height: 100vh;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    z-index: 9;
}

.contact-main .contact-row .contact-content {
    padding: 10px;
    color: #fff;
}

.contact-main .contact-row .contact-content h2 {
    color: #fff;
    margin-bottom: 20px;
    line-height: 1;
}

.contact-main .contact-row .contact-content p {
    color: #fff;
}

.contact-main .contact-row .contact-content .btn-3 {
    min-width: auto;
}

/* Services-page-css-start */
.service-banner {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
    /*  http://propertyone.com.cy/images/ */
    background-image: url(https://propertyone.com.cy/images/our-services.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 550px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.service-banner::before {
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: #000;
    opacity: 0.5;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    z-index: 1;
}


.service-banner .service-banner-row {
    min-height: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 9;
}

.service-banner .service-banner-row .service-banner-content h1 {
    color: #fff;
    /* font-family: "Montserrat", Sans-serif; */
    font-family: "Mont", Sans-serif;
    font-weight: 500;
    margin-bottom: 0px;
}

.down-btn {
    display: none;
}

.service-banner .service-banner-row .service-banner-content .down-btn a {
    background-color: #02010100;
    font-size: 20px;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: var(--e-global-color-secondary);
    padding: 0;
    display: inline-block;
    color: #fff;
    font-family: "Instrument Sans", Sans-serif;
    width: 44.5px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    text-decoration: none;
}

.service-banner .service-banner-row .service-banner-content .down-btn a:hover {
    background-color: #fff;
    text-align: center;
    color: #000;
    transition: all .3s;
}

.services-main {
    padding: 80px 0px 0px 0px;
}

.services-main:first-child {
    padding: 70px 0px 0px 0px;
}

.services-main .services-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.services-main .services-row .left-col {
    width: 49.999%;
}

.services-main .services-row .left-col .left-col-img {
    padding: 10px;
    margin-right: 20.98px;
}

.services-main .services-row .right-col {
    width: 50.001%;
}


.services-main .services-row .left-col .left-col-img img {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.services-main .services-row .right-col .right-col-content {
    padding: 10px;
    height: auto;
    overflow: visible;
}

.services-main .services-row .right-col .right-col-content h2 {
    line-height: 1;
    margin-bottom: 20px;
    /* font-family: "Montserrat", Sans-serif; */
    font-family: "Mont", Sans-serif;
    font-weight: 500;
}

.services-main .services-row .right-col .right-col-content p {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    padding-top: 10px;
    margin-bottom: 26px;
}

.services-main .services-row .right-col .right-col-content li {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    display: block;
    margin: 0;

}

.services-main .services-row .right-col .right-col-content li span {
    display: inline;
    color: inherit;
    background-color: inherit;
    margin: 0;
}

.services-main .services-row .right-col .right-col-content ul {
    padding: 0;
}

.services-main .services-row .right-col .right-col-content .btn-row {
    margin-top: 50px;
}

.services-main .services-row .right-col .right-col-content .btn-row a {
    min-width: auto;
}

.services-perent .services-main:nth-child(2n+2) .services-row {
    flex-direction: row-reverse;
}

.services-perent .services-main:nth-child(2n+2) .services-row .left-col .left-col-img {
    margin-left: 20.98px;
    margin-right: 0;
}

.services-perent {
    padding-bottom: 120px;
}

.mobail-title {
    display: none;
}

.mobail-title h2 {
    /* font-family: "Montserrat", Sans-serif; */
    font-family: "Mont", Sans-serif;
}

/* Services-page-css-end */

/* about-page-css-start */

.about-banner {
    background-image: url(../images/meet-team.jpg);
}

.about-banner::before {
    opacity: 0.61;
}

.about-col-sec {
    padding: 100px 0px 50px 0px;
}

.about-col-sec .about-sec-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.about-col-sec .about-sec-row .about-left-col {
    width: 49.999%;
}


.about-col-sec .about-sec-row .about-left-col .about-left-img {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-col-sec .about-sec-row .about-left-col .about-left-img img {
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
}

.about-col-sec .about-sec-row .about-right-col {
    width: 50.001%;
}

.about-col-sec .about-sec-row .about-right-col .about-right-content {
    padding: 10px;
}

.about-col-sec .about-sec-row .about-right-col .about-right-content .about-right-img {
    margin-bottom: 20px;
}

.about-col-sec .about-sec-row .about-right-col .about-right-content p {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    padding-top: 10px;
}

.about-col-sec .about-sec-row .about-right-col .about-right-content p:last-child {
    margin-bottom: 0;
}

.about-partners-sec {
    margin: 0;
    padding: 50px 0px 100px 0px;
}

.about-partners-sec .marquee {
    margin-bottom: 0;
}

.video-sec {
    padding: 0 0 80px 0;
}

.video-sec .video-main-row {
    padding: 10px;
}

/* about-page-css-end */


/* contact-page-css-end */

.contact-banner {
    background-image: url(../images/contact-bg.jpg);
    /* padding: 180px 0px 80px 0px; */
}

.contact-banner .service-banner-row {
    /* min-height: auto; */
    padding: 10px;
}

.contact-banner .service-banner-row .service-banner-content h1 {
    margin-bottom: 0;
    /* font-family: "Instrument Sans", Sans-serif; */
    font-family: "Mont", Sans-serif;
    font-weight: 400;
}

.form-main-sec {
    padding: 70px 15px 70px 15px;
}

.form-main-sec .form-main-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.form-main-sec .form-main-row .form-left-col {
    width: 40%;
}

.map-col iframe {
    height: 280px;
    margin-bottom: 20px;
}

.form-main-sec .form-main-row .form-right-col {
    width: 60%;
    padding-left: 50px;
    position: relative;
}

.form-main-sec .form-main-row .form-left-col .form-left-img {
    margin-top: 20px;
}

.form-title-row {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.form-title-row .form-title-left {
    width: 43.165%;
    padding: 10px;
}

.form-title-row .form-title-left h2 {
    line-height: 1;
}

.form-title-row .form-title-right {
    width: 56.835%;
    padding: 10px;
}

.form-title-row .form-title-right ul {
    display: flex;
    justify-content: end;
    align-items: center;
}

.form-title-row .form-title-right ul li {
    border-right: 2px solid #DEDEDE;
}

.form-title-row .form-title-right ul li:last-child {
    border-right: 0;
}

.form-title-row .form-title-right ul li a {
    font-size: 17px;
    font-weight: 400;
    text-align: right;
    color: #000;
    font-family: "Instrument Sans", Sans-serif;
    text-decoration: none;
    margin: 0 8px;
    display: inline-block;
}

.contact-form .form-input-row-sec .input-group input,
.contact-form .form-input-row-sec .input-group select {
    border: 1px solid #a7a7a7;
    padding: 10px 15px 10px 15px;
    width: 100%;
    height: 46px;
    color: #000000;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 15px;
    outline: none;
    font-family: "Montserrat", Sans-serif;
}

.contact-form .form-input-row-sec .input-group textarea {
    width: 100%;
    height: 120px;
    margin-bottom: 15px;
    padding: 10px 15px 10px 15px;
    outline: none;
    font-size: 15px;
    font-weight: 500;
    font-family: "Montserrat", Sans-serif;
}

.submit-row {
    display: flex;
    flex-wrap: wrap;
}

.submit-row .btn {
    min-width: auto;
    padding: 14px 50px 14px 50px;
    margin: 15px 0px 0px 0px;
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
    font-weight: 500;
    border-width: 1px 1px 1px 1px;
    cursor: pointer;
    z-index: 99;
    position: relative;
    display: inline-block;
}

.submit-row .btn-2 {
    min-width: auto;
    padding: 14px 50px 14px 50px;
    margin: 15px 0px 0px 20px;
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
    font-weight: 500;
    border-width: 1px 1px 1px 1px;
    cursor: pointer;
    z-index: 99;
    position: relative;
    display: inline-block;
}

.contact-social {
    position: absolute;
    bottom: 10px;
    right: 0;
}

.contact-social ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    align-items: center;
}

.contact-social ul li:nth-child(1) {
    margin-left: 0px;
}

.contact-social ul li {
    margin-left: 20px;
    font-size: 21px;
}

.contact-social ul li a {
    color: #000;
    transition: all 0.3s;
    font-family: "Instrument Sans", Sans-serif;
}

.contact-social ul li a:hover {
    color: #A89A80;
}

/* contact-page-css-end */




.banner-sec {
    position: relative;
}

.bg-slider-main {
    position: relative;
}


.banner-slider .item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.banner-sec .owl-thumbs {
    position: absolute;
    bottom: 30px;
    max-width: 390px !important;
    left: 50%;
    margin: 0 auto !important;
    z-index: 99;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    transform: translateX(-50%);
}

.banner-sec .owl-thumbs .item {
    border: 1px solid #fff;
    min-width: 125px;
    position: relative;
    height: 54px;
    opacity: 0.7;
}

.banner-sec .owl-thumbs .item img {
    height: 100%;
    object-fit: cover;
}

.banner-sec .owl-thumbs .owl-item.current .item {
    opacity: 1;
}

.banner-sec .banner-slider .owl-stage-outer .item::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #00000026;
}

.banner-sec .banner-slider .owl-stage-outer .item {
    position: relative;
    height: 100%;
}

.banner-slider .owl-item {
    height: 100vh;
}

ul.bottom-btns {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    bottom: 30px;
    left: 19px;
    z-index: 1;
    gap: 36px;
}

ul.bottom-btns li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;
}

ul.bottom-btns li .text-img {
    width: 36px;
    height: 36px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    font-size: 19px;
}

ul.bottom-btns li p {
    margin: 0;
    color: #fff;
    font-weight: bold;
}

.lido-sec-two {
    padding: 80px 0px 40px 0px;
    position: relative;
}


.lido-sec-two::after {
    content: '';
    position: absolute;
    right: 10%;
    top: 10%;
    background-image: radial-gradient(black 8%, transparent 9%);
    background-size: 43px 44px;
    background-position: center;
    background-repeat: repeat;
    width: 96px;
    height: 96px;
    z-index: 1;
}

.lido-sec-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.lido-sec-row .left-img-col {
    width: 49.26%;
    padding: 0px 10px;
}

.right-content-col {
    width: 50.74%;
    padding-left: 20px;
}

.content-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}

.content-title .user-logo {
    width: 79px;
    border-radius: 50%;
    overflow: hidden;
}

.title-name {
    width: 86%;
    padding-left: 15px;
}

.right-content-col>h2 {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px;
}

.about-title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
}

.about-title span {
    display: inline-block;
    margin: 0px 8px;
}

.right-col-content ul {
    padding-top: 15px;
}

.right-col-content ul li {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.right-col-content ul li span {
    background-color: #77b255;
    color: #fff;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}

.right-col-content ul li span i {
    font-size: 12px;
}


.show-content-btns h6.show-more {
    display: inline-block;
}

.show-content-btns.active-btn h6.show-more,
.show-content-btns h6.show-less {
    display: none;
}

.show-content-btns.active-btn h6.show-less {
    display: inline-block;
}

ul.btns-sec li {
    min-width: 215px;
    text-align: center;
}

h6.content-btn {
    display: inline-block;
    margin-top: 30px;
    font-size: 13px;
    font-weight: 600;
    padding-bottom: 2px;
    position: relative;
}

h6.content-btn::before {
    content: '';
    display: block;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 0;
    background: #000;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    position: absolute;
    bottom: 0;
    z-index: 1;
}

h6.content-btn::after {
    content: '';
    display: block;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background: #cccccc;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    position: absolute;
    bottom: 0;
}

h6.content-btn:hover:before {
    width: 100%;
}

.right-col-content {
    height: 180px;
    overflow: hidden;
}

.right-col-content.show-content {
    height: auto;
}

ul.btns-sec {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 40px;
}

ul.btns-sec li {
    margin: 0px 15px 15px 0px;
}

ul.socail-icons {
    margin-top: 20px;
    margin-bottom: 10px;
}

ul.socail-icons li a {
    display: flex;
    width: 36px;
    height: 36px;
    color: #000;
    border: 1px solid #000000;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 50%;
    margin: 5px;
    transition: all .3s;
}

ul.socail-icons li a:hover {
    background-color: #000;
    color: #fff;
}

ul.socail-icons li {
    display: inline-block;
}

ul.socail-icons span {
    font-weight: bold;
    margin-right: 9px;
}

.rooom-details-sec {
    padding: 30px 0px 80px 0px;
}

.details-row {
    padding-top: 30px;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.details-row li {
    width: calc(100%/3);
    padding: 21px 23px 22px !important;
    display: block;
    border-right: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
}

.details-row li:nth-child(3n+1) {
    border-left: 1px solid #EEEEEE;
}

.details-row li:nth-child(1) {
    border-top: 1px solid #EEEEEE;
}

.details-row li:nth-child(2) {
    border-top: 1px solid #EEEEEE;
}

.details-row li:nth-child(3) {
    border-top: 1px solid #EEEEEE;
}


.details-row li span {
    color: #9f9f9f;
    font-size: 22px;
    line-height: 28.6px;
    font-weight: 400;
}

.details-row li span span {
    font-size: 17px;
    line-height: 21.94px;
    color: #000;
    font-weight: 500;
    margin-top: 5px;
    display: block;
}

.video-sec {
    width: 100%;

}

.video-sec iframe {
    width: 100%;
    height: 100%;
}

.features-sec {
    padding: 80px 0px 80px 0px;
}

.features-sec h2 {
    margin-bottom: 40px;
}

.features-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    gap: 30px;
    position: relative;
    padding-bottom: 30px;
}

.features-row::after {
    content: '';
    content: "";
    position: absolute;
    right: -4px;
    bottom: -17px;
    background-image: radial-gradient(black 8%, transparent 9%);
    background-size: 43px 44px;
    background-position: center;
    background-repeat: repeat;
    width: 96px;
    height: 96px;
    z-index: 1;
}

.features-row .details-col {
    width: calc(100%/2 - 30px/2);
    border: solid 1px #eeeeee;
    padding: 29px 38px 34px !important;
}

.details-col>h3 {
    color: #9F9F9F;
    font-size: 24px;
    margin-bottom: 20px;
}

.details-col ul li {
    line-height: 1.88;
    font-size: 16px;
    letter-spacing: 1px;
    display: flex;
    flex-wrap: wrap;
}

.details-col ul li p {
    line-height: 1.88;
}

.details-col ul li strong {
    font-weight: 600;
    margin-right: 10px;
}

.home-viwe-sec {
    padding: 10px 0px 30px;
}

.home-viwe-sec .matterport-showcase iframe {
    width: 100%;
    height: 758px;
    max-width: 1350px;
    margin: 0 auto 15px;
}


.home-viwe-sec .container {
    max-width: 1380px;
}

.home-viwe-sec ul.btns-sec {
    gap: 20px;
    margin-top: 45px;
    padding: 0px 4px;
}

.home-viwe-sec ul.btns-sec li {
    width: 100%;
    margin: 0;
    width: calc(100% / 2 - 20px / 2);
}


.loction-sec {
    width: 100%;
}

.loction-sec iframe {
    width: 100%;
    height: 70vh;
    filter: brightness(106%) contrast(107%) saturate(0%) blur(0px) hue-rotate(0deg);
}

.loction-sec h2 {
    text-align: center;
    padding: 20px;
    margin-bottom: 20px;

}


.banner-title {
    max-width: 650px;
    margin: auto;
}


.banner-title h2 {
    color: #fff;
    margin-bottom: 20px;
}

.banner-title p {
    color: #fff;
}

.banner-sec-two {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #000;
    padding: 30px 30px;
    top: 0;
    left: 0;
    z-index: 99999;
    opacity: 0;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}


.bg-slider-main-two {
    max-width: 1200px;
    margin: auto;
    width: 100%;
}

.bg-slider-main-two .owl-thumbs {
    margin-top: 10px;
}

.banner-sec-two.show-slider {
    visibility: visible;
    opacity: 1;
}

.close-slider-btn {
    position: absolute;
    width: 40px;
    height: 40px;
    background: #1E1E1E;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 40px;
    top: 40px;
    font-size: 24px;
    padding: 8px;
    cursor: pointer;
}

.banner-sec-two .owl-nav button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 !important;
    margin: 0 !important;
    font-size: 20px !important;
    background-color: transparent !important;
    cursor: pointer;
}

.banner-sec-two .owl-nav button.owl-prev {
    left: 15px;
}

.banner-sec-two .owl-nav button.owl-next {
    right: 15px;
}

.banner-sec-two .owl-nav button svg {
    width: 19px;
    color: #fff;
}

.banner-sec-two .owl-nav button svg path {
    stroke: #fff !important;
    color: #fff !important;
}

.share-popup {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999999999999;
    background: rgb(0 0 0 / 43%);
    padding: 15px;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
}

.share-popup.share-popup-active {
    visibility: visible;
    opacity: 1;
}

.share-popup-container {
    background: #fff;
    position: relative;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    padding: 40px 30px;
    border-radius: 5px;
    padding-top: 30px;
}

.share-popup-container h3 {
    margin-bottom: 20px;
    font-size: 24px !important;
    font-weight: 500;
}

.share-popup-container span.share-popup-close {
    position: absolute;
    right: 15px;
    text-align: center;
    font-size: 24px;
    cursor: pointer;
    top: 24px;
}

.share-form li,
.request-form-main li {
    margin-bottom: 15px;
}

.share-form li input,
.request-form-main li input,
.request-form-main li textarea {
    font-family: "Montserrat", Sans-serif;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 15px 10px 15px;
    width: 100%;
    min-height: 46px;
    color: #000000;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
}

.request-form-main li textarea {
    height: 140px;
    resize: none;
}

.request-form-main .btn,
.share-form .btn {
    font-size: 15px;
    font-weight: 500;
    padding: 12px 50px 12px 50px !important;
    margin: 0;
    color: #ffffff;
    cursor: pointer;
    width: 100%;
}

.request-form-main .btn::placeholder,
.share-form .btn::placeholder {
    color: #fff !important;
}

.request-form-main .btn:hover::placeholder,
.share-form .btn:hover::placeholder {
    color: #000 !important;
}


.request-info-popup,
.request-view-popup {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999999999999;
    background: rgb(0 0 0 / 43%);
    padding: 15px;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
}

.request-info-popup.request-popup-active,
.request-view-popup.request-view-active {
    opacity: 1;
    visibility: visible;
}

.request-info-popup .request-popup-container,
.request-view-popup .request-popup-container {
    background: #fff;
    position: relative;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    padding: 40px 30px;
    border-radius: 5px;
    padding-top: 30px;
}

.request-info-popup .request-popup-container h3,
.request-view-popup .request-popup-container h3 {
    margin-bottom: 20px;
    font-size: 24px !important;
    font-weight: 500;
}

.request-info-popup .request-popup-container .request-popup-close,
.request-view-popup .request-popup-container .request-popup-close {
    position: absolute;
    right: 15px;
    text-align: center;
    font-size: 24px;
    cursor: pointer;
    top: 24px;
}

.footer-top-sec .left-menu h4,
.footer-top-sec .right-menu h4 {
    line-height: 1;
}

.about-video-banner {
    width: 100%;
    margin-bottom: 80px;
}

.about-video-banner .main-banner {
    max-width: 1362px;
    padding: 10px;
    height: 943.88px;
    margin: auto;
}

.about-video-banner .main-banner::before {
    content: '';
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    top: 10px;
    left: 10px;
}

.about-video-banner .main-banner .bg-video-container {
    position: unset;
    overflow: visible;
    background-color: transparent;
}

.about-video-banner .main-banner .banner-logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.insight-repeater-sec .container {
    max-width: 1170px;
}


.single-post-banner-dis {
    width: 100%;
    position: relative;
    z-index: 99;
}


.filter-sec.all-tab {
    padding-bottom: 20px;
}

.filter-sec.all-tab .sort-by {
    width: 100%;
}

.video-3d-sec {
    width: 100%;
    height: 100vh;
}

.video-3d-sec>span {
    display: block;
    width: 100%;
    height: 100%;
}

.video-3d-sec iframe {
    width: 100%;
    height: 100%;
}

.detail-video .main-banner .by-youtube-video {
    width: 100%;
    height: 100%;
}

.detail-video .main-banner .by-youtube-video>p,
.detail-video .main-banner .by-youtube-video>p>span,
.detail-video .main-banner .by-youtube-video iframe {
    width: 100%;
    height: 100%;
    display: block;
}

.detail-video .main-banner {
    padding: 0;
}

.about-video-banner.detail-video {
    margin-bottom: 0;
}

.load-message {
    text-align: center;
    margin-top: 25px;
}

.image-gallery-sec .property-content-full p {
    display: block;
}

/* sell-css start */

.exceptional-sec {
    padding: 186px 0 157px;
}

.exceptional-sec .exceptional-content {
    text-align: center;
    max-width: 744px;
    margin: auto;
}

.exceptional-sec .exceptional-content h2 {
    font-weight: bold;
    margin-bottom: 20px;
}

.exceptional-sec .exceptional-content p {
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    letter-spacing: 0;
}

.counter-sec {
    padding: 80px 0px;
}

.counter-sec .counter-container {
    display: flex;
    justify-content: space-between;
    text-align: center;
    max-width: 1146px;
    margin: auto;
}

.counter-sec .counter-container .counter-box {
    padding: 30px 40px 54px;
    border-left: 1px solid #A89A80;
}

.counter-sec .counter-container .counter-box:nth-child(3n+1) {
    border-left: 0px;
}

.counter-sec .counter-container .count-one {
    width: 29.7%;
}

.counter-sec .counter-container .count-two {
    width: 40.6%;
}

.counter-sec .counter-container .count-three {
    width: 29.7%;
}

.counter-sec .counter-container .counter {
    font-family: "Mont", Sans-serif;
    font-weight: 800;
    font-size: 55.43px;
    line-height: 60px;
    text-align: center;
    vertical-align: middle;
    letter-spacing: 0;
    margin-bottom: 10px;
    display: inline-block;
}

.counter-sec .counter-container .countert-icon {
    font-family: "Mont", Sans-serif;
    font-weight: 800;
    font-size: 55.43px;
    line-height: 60px;
    text-align: center;
    vertical-align: middle;
    letter-spacing: 0;
    margin-bottom: 10px;
    display: inline-block;
}

.counter-sec .counter-container .counter-box p {
    font-style: italic;
    font-size: 14.48px;
    letter-spacing: 0.4px;
    text-align: center;
    vertical-align: middle;
}

.looking-sec {
    padding: 177px 0 129px;
}

.looking-sec .container {
    max-width: 1176px;
}

.looking-sec .looking-row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-right: -30px;
    margin-left: 30px;
    margin-bottom: -60px;
    position: relative;
    z-index: 1;
}

.looking-sec .looking-row .left-img-col {
    width: 41.4%;
    padding-right: 15px;
    position: relative;
}


.looking-sec .looking-row .left-img-col::after {
    content: '';
    position: absolute;
    width: 166px;
    height: 1px;
    background-color: #A89A80;
    right: -86px;
    top: 80px;
}

.looking-sec .looking-row .left-img-col img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.looking-sec .looking-row .looking-right-content {
    width: 58.6%;
    padding-left: 125px;
    padding-bottom: 80px;
}

.looking-sec .looking-row .looking-right-content h2 {
    font-size: 36px;
    line-height: 1.45;
    font-weight: bold;
    margin-bottom: 20px;
}

.looking-bottom-row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 30px;
    margin-right: -30px;
    padding-left: 16.5%;
}

.looking-bottom-row .bottom-left-img-col {
    width: 50.8%;
    position: relative;
}

.looking-bottom-row .bottom-left-img-col::after {
    content: '';
    position: absolute;
    width: 166px;
    height: 1px;
    background-color: #A89A80;
    right: -70px;
    top: 60px;
}

.looking-bottom-row .bottom-left-img-col img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.looking-bottom-row .bottom-right-content {
    width: 49.2%;
    padding-left: 88px;
}

.looking-bottom-row .bottom-right-content h3 {
    font-weight: 700;
    font-size: 26px;
    line-height: 1.5;
    letter-spacing: 1px;
    max-width: 94%;
    vertical-align: middle;
    margin-bottom: 30px;
}

.looking-bottom-row .bottom-right-content ul {
    list-style: disc;
    font-family: "Instrument Sans", Sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    vertical-align: middle;
    column-count: 2;
    column-gap: 49px;
    color: #000;
    letter-spacing: 0;
}

.looking-bottom-row .bottom-right-content ul li {
    margin-bottom: 37px;
    list-style: disc;
}

.looking-bottom-row .bottom-right-content ul li::marker {
    font-size: 11px;
}

.video-repeater-sec .container {
    max-width: 1176px;
}

.video-repeater-col {
    padding: 110px 0;
    text-align: center;
    position: relative;
}

.video-repeater-col::before {
    content: '';
    position: absolute;
    width: 166px;
    height: 1px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #A89A80;
}

.video-repeater-col:last-child::after {
    content: '';
    position: absolute;
    width: 166px;
    height: 1px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #A89A80;
}

.video-repeater-col .video-repeater-3d-sec {
    width: 100%;
}

.video-repeater-col .video-repeater-3d-sec iframe {
    width: 100%;
    height: 644px;
}

.video-repeater-col .video-repeater-title {
    max-width: 710px;
    margin: auto;
    margin-bottom: 61px;
}

.video-repeater-col .video-repeater-title h2 {
    font-weight: bold;
}

.sell-property-sec {
    padding: 111px 0px 171px;
}

.sell-property-sec .container {
    max-width: 1176px;
}

.sell-property-sec .sell-property-title {
    text-align: center;
    margin-bottom: 110px;
}

.sell-property-sec .sell-property-title h2 {
    font-weight: bold;
}

.sell-property-sec .sell-form-col {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.sell-property-sec .sell-form-col .form-col-left {
    width: 43.2%;
    padding: 37px 40px 46px 40px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.17);
    border: 1px solid rgba(255, 255, 255, 1);
}

.sell-property-sec .sell-form-col .form-img-right {
    width: 56.8%;
}

.sell-property-sec .sell-form-col .form-img-right img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sell-property-sec .contact-form .form-input-row-sec .input-group input{
    border-color: rgba(231, 231, 231, 1);
    height: 41px;
    padding: 12px 11px 12px 11px;
}


.sell-property-sec .contact-form .form-input-row-sec .input-group select {
    padding: 9px 11px 9px 11px;
    border-color: rgba(231, 231, 231, 1);
}

.sell-property-sec .contact-form .form-input-row-sec .input-group textarea {
    border-color: rgba(231, 231, 231, 1);
}

.sell-property-sec .contact-form .form-input-row-sec .input-group input::placeholder,
.sell-property-sec .contact-form .form-input-row-sec .input-group textarea::placeholder{
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    letter-spacing: 0%;
    vertical-align: middle;
    color: rgba(117, 117, 117, 1);
    font-family: "Instrument Sans", Sans-serif;
}

.sell-property-sec .contact-form .submit-row .btn {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.footer-top-sec .right-menu ul li {
    font-size: 14px;
    line-height: 2em;
}

.repeater-img-slider .owl-nav {
    margin: 0;
}

.repeater-img-slider .owl-nav button {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin: 0 !important;
    width: 25px;
    height: 29px;
    background-color: #fff !important;
    border-radius: 0 !important;
    clip-path: polygon(0% 50%, 100% 0%, 100% 100%);
}

.repeater-img-slider .owl-nav button.owl-prev {
    left: 42px;
}

.repeater-img-slider .owl-nav button.owl-next {
    right: 42px;
    left: auto;
    clip-path: polygon(100% 50%, 0% 0%, 0% 100%);
}

.repeater-img-slider .owl-nav button i {
    display: none;
}

.repeater-img-slider .owl-dots {
    display: none;
}

@media (max-width:1440px) {
    .exceptional-sec {
        padding: 96px 0 107px;
    }

    .counter-sec {
        padding: 60px 0px;
    }

    .looking-sec {
        padding: 97px 0 109px;
    }

    .sell-property-sec {
        padding: 101px 0px 121px;
    }
}

@media (max-width:1280px) {
    .looking-sec .looking-row {
        margin-right: 0;
        margin-left: 0;
    }

    .looking-bottom-row {
        margin-right: 0;
        margin-left: 0;
    }
}

/* sell-css end */
@media (max-width:1200px) {
    .form-title-row .form-title-right ul {
        flex-wrap: wrap;
    }

    .contact-social ul li {
        margin-left: 12px;
        font-size: 21px;
    }

    .form-title-row .form-title-right ul li {
        border-right: 0px solid #DEDEDE;
    }

    ul.bottom-btns {
        position: relative;
        bottom: 0px;
        left: 0px;
        z-index: 1;
        gap: 20px;
    }

    ul.bottom-btns li {
        width: calc(100%/2 - 20px/2);
        margin: auto;
        justify-content: center;
        margin-top: 40px;
    }

    ul.bottom-btns li .text-img {
        background-color: #000000;
        color: #fff;
    }

    ul.bottom-btns li p {
        color: #000000;
    }

    .lido-sec-two::after {
        right: 5%;
        top: 5%;
        background-size: 34px 34px;
        width: 76px;
        height: 76px;
    }

    .features-row::after {
        right: -4px;
        bottom: -9px;
        background-size: 34px 34px;
        width: 76px;
        height: 76px;

    }

    .content-title .user-logo {
        width: 12%;
    }

    .banner-sec .owl-thumbs .item {
        min-width: auto;
        height: auto;
    }
}

@media (max-width:1170px) {

    .counter-sec .counter-container .counter,
    .counter-sec .counter-container .countert-icon {
        font-size: 35.43px;
        line-height: 40px;

    }

    .counter-sec .counter-container .counter-box {
        padding: 25px 20px 25px;
        border-left: 1px solid #A89A80;
    }

    .exceptional-sec {
        padding: 66px 0 7px;
    }

    .looking-sec {
        padding: 47px 0 89px;
    }

    .video-repeater-col {
        padding: 70px 0;
    }

    .sell-property-sec {
        padding: 71px 0px 81px;
    }
}


@media (max-width:1024px) {

    h3 {
        font-size: 28px;
    }

    h2 {
        font-size: 26px;
    }

    .about-main .container {
        max-width: 630px;
    }

    .about-main .about-row .left-col {
        width: 100%;
    }

    .about-main .about-row .left-col-img {
        margin-right: 0;
    }

    .about-main .about-row .right-col {
        width: 100%;
        margin-top: 20px;
    }

    .about-main .about-row .right-col .right-col-content {
        margin-left: 0;
    }

    .exclusive-listings .list-property>li {
        width: calc(100% / 2 - 15px / 2);
        margin: 0 0 20px 15px;
    }

    .exclusive-listings .list-property>li:nth-of-type(3n+1) {
        margin-left: 20px;
    }

    .exclusive-listings .list-property>li:nth-of-type(2n+1) {
        margin-left: 0px;
    }

    .exclusive-listings {
        padding: 50px 10px 50px 10px;
    }

    .testimonials-sec .testimonials-main {
        max-width: 700px;
    }

    .latest-properties .list-property>li {
        width: calc(100% / 2 - 15px / 2);
        margin: 0 0 20px 15px;
    }

    .latest-properties .list-property>li:nth-of-type(3n+1) {
        margin-left: 20px;
    }

    .latest-properties .list-property>li:nth-of-type(2n+1) {
        margin-left: 0px;
    }

    .team-sec .container {
        max-width: 630px;
    }

    .team-sec .team-row .team-left-col {
        width: 100%;
    }

    .team-sec .team-row .team-left-col .team-left-col-content {
        text-align: center;
        margin: 0;
    }

    .team-sec .team-row .team-right-col {
        width: 100%;
        margin-top: 30px;
    }

    .team-sec .team-row .team-right-col .team-right-col-img img {
        max-width: 100%;
    }

    .services-main .services-row .left-col {
        width: 100%;
    }

    .services-main .services-row .left-col .left-col-img {
        padding: 10px;
        margin-right: 0;
    }

    .services-main .services-row .right-col {
        width: 100%;
        margin-top: 20px;
    }

    .services-perent .services-main:nth-child(2n+2) .services-row .left-col .left-col-img {
        margin-left: 0;
        margin-right: 0;
    }

    .about-col-sec .about-sec-row .about-left-col {
        width: 100%;
    }

    .about-col-sec .about-sec-row .about-left-col .about-left-img img {
        width: 100%;
    }

    .about-col-sec .about-sec-row .about-right-col {
        width: 100%;
    }

    .about-col-sec {
        padding: 70px 0px 0px 0px;
    }

    .form-main-sec .form-main-row .form-left-col {
        width: 100%;
    }

    .form-main-sec .form-main-row .form-right-col {
        width: 100%;
        padding-left: 0;
        margin-bottom: 50px;
    }

    .map-col iframe {
        height: 280px;
        margin-bottom: 20px;
        width: 100%;
    }

    .form-main-sec .form-main-row {
        flex-direction: column-reverse;
    }

    .form-main-sec .container {
        max-width: 630px;
    }

    .home-viwe-sec ul.btns-sec {
        max-width: 588px;
        margin: auto;
        margin-top: 45px;
    }

    .looking-bottom-row {
        padding-left: 7.5%;
    }

    .looking-sec .looking-row .left-img-col {
        width: 45.4%;
    }

    .looking-sec .looking-row .looking-right-content {
        width: 54.6%;
        padding-left: 65px;
        padding-bottom: 80px;
    }

    .looking-sec .looking-row .left-img-col::after {
        width: 136px;
        right: -36px;
        top: 50px;
    }

    .looking-bottom-row .bottom-left-img-col::after {
        width: 136px;
        right: -56px;
        top: 50px;
    }

    .looking-bottom-row .bottom-right-content {
        width: 49.2%;
        padding-left: 68px;
    }

    .looking-sec .looking-row .looking-right-content h2 {
        font-size: 28px;
    }

}


@media (max-width:767px) {

    h1 {
        font-size: 34px;
        line-height: 1.2em;
    }


    .team-sec .team-row .team-left-col .team-left-col-content p {
        font-size: 14px;
    }

    .about-main .about-row .right-col .right-col-content .btn-row a.btn-2 {
        display: none;
    }

    .about-main .about-row .right-col .right-col-content .btn-row a {
        width: 100%;
        min-width: auto;
    }

    .about-main {
        margin-top: 20px;
        margin-bottom: 0px;
        padding-top: 20px;
    }

    .about-main .container {
        max-width: 630px;
        padding: 0px 10px;
    }

    .partners-sec {
        margin-top: 60px;
        margin-bottom: 0;
    }

    .partners-sec .marquee-item {
        width: 200px;
    }

    .exclusive-listings .list-property>li {
        width: 100%;
        margin-left: 0 !important;
    }

    .exclusive-listings {
        padding: 50px 0px 50px 0px;
    }

    .exclusive-listings .container {
        padding: 10px 10px;
    }

    .testimonials-sec {
        padding: 50px 0px 60px 0px;
    }

    .latest-properties .list-property>li {
        width: 100%;
        margin-left: 0 !important;
    }

    .latest-properties .list-property>li .property-content {
        padding: 10px 15px;
    }

    .services-main {
        padding: 60px 0px 0px 0px !important;
    }

    .services-perent {
        padding-bottom: 70px;
    }

    h2.d-title {
        display: none;
    }

    .mobail-title {
        display: block;
        padding-bottom: 30px;
        text-align: center;
    }

    .about-col-sec .about-sec-row .about-right-col .about-right-content .about-right-img {
        display: none;
    }

    .about-col-sec .about-sec-row .about-left-col .about-left-img {
        padding: 0px;
    }

    .about-col-sec .about-sec-row .about-right-col .about-right-content {
        padding: 0px;
    }

    .about-col-sec .about-sec-row .about-right-col {
        margin-top: 20px;
    }

    .about-col-sec .about-sec-row .about-right-col .about-right-content p {
        font-size: 16px;
        line-height: 24px;
    }

    .about-partners-sec {
        margin: 0;
        padding: 50px 0px 50px 0px;
    }

    .video-sec .video-main-row {
        padding: 10px 0px;
    }

    .video-sec {
        padding: 0 0 60px 0;
    }

    .form-main-sec {
        padding: 50px 10px 50px 10px;
    }

    .contact-social {
        position: relative;
        bottom: 0;
        margin-top: 20px;
    }

    .contact-social ul {
        justify-content: center;
    }

    .contact-social ul li {
        margin-left: 20px;
        font-size: 21px;
    }

    .form-title-row .form-title-left {
        width: 100%;
        padding: 10px;
        text-align: center;
    }

    .form-title-row .form-title-right {
        width: 100%;
        padding: 10px;
    }

    .form-title-row .form-title-right ul {
        flex-wrap: nowrap;
        justify-content: center;
    }

    .submit-row .btn,
    .submit-row .btn-2 {
        width: 100%;
        margin-left: 0;
    }

    .form-title-row .form-title-right ul li {
        border-right: 2px solid #DEDEDE;
    }

    .lido-sec-row .left-img-col {
        width: 100%;
        padding: 0px 10px;
    }

    .right-content-col {
        width: 100%;
        padding-left: 0px;
        padding-top: 20px;
    }

    .content-title {
        flex-wrap: wrap;
    }

    .title-name {
        width: 100%;
        padding-left: 0;
        text-align: center;
    }

    .content-title .user-logo {
        width: 26%;
        margin: auto;
    }

    .title-name h4 {
        line-height: 24px;
    }

    .right-content-col>h2 {
        font-size: 22px;
    }

    .about-title {
        font-size: 14px;
    }

    .right-col-content ul li {
        font-size: 14px;
    }

    .right-col-content ul li span {
        width: 14px;
        height: 14px;
    }

    .right-col-content ul li span i {
        font-size: 9px;
    }

    .rooom-details-sec {
        padding: 0px 0px 60px 0px;
    }

    .details-row {
        padding-top: 0;
    }

    .details-row li span {
        font-size: 19px;
        line-height: 25px;
    }

    .details-row li span span {
        font-size: 16px;
        line-height: normal;
    }

    .details-row li {
        width: calc(100% / 2);
        padding: 15px !important;
    }

    .details-row li:nth-child(3n+1) {
        border-left: 0px solid #EEEEEE;
    }

    .details-row li:nth-child(2n+1) {
        border-left: 1px solid #EEEEEE;
    }

    .details-row li:nth-child(3) {
        border-top: 0px solid #EEEEEE;

    }

    .home-viwe-sec ul.btns-sec {
        flex-direction: column;
        padding: 0;
    }

    .home-viwe-sec ul.btns-sec li {
        width: 100%;
        max-width: 420px;
    }

    .home-viwe-sec .matterport-showcase iframe {
        height: 488px;
        margin-top: 16px;
    }

    .features-row .details-col {
        width: 100%;
        padding: 19px 20px 15px !important;
    }

    .details-col>h3 {
        font-size: 20px;
    }

    .details-col ul li {
        font-size: 14px;
    }

    .features-sec {
        padding: 60px 0px 60px 0px;
    }

    ul.bottom-btns {
        gap: 0;
    }

    ul.bottom-btns li {
        width: auto;
    }

    ul.bottom-btns li .text-img {
        margin-right: 15px;
        font-size: 19px;
    }

    .lido-sec-two .container,
    .rooom-details-sec .container,
    .features-sec .container,
    .home-viwe-sec .container {
        padding: 0 10px;
    }

    .lido-sec-row .left-img-col {
        width: 100%;
        padding: 0px 0px;
    }

    .lido-cideo-sec {
        padding-bottom: 0;
    }

    .loction-sec h2 {
        font-size: 22px;
    }

    .service-banner {
        min-height: 358px;
    }

    .service-banner .service-banner-row {
        min-height: 278px;
    }

    .about-video-banner .main-banner {
        height: 514.88px;
    }

    .about-video-banner {
        margin-bottom: 60px;
        width: 100%;
    }

    .looking-sec .looking-row .looking-right-content {
        width: 100%;
        padding-left: 0;
        padding-bottom: 0;
        padding-top: 40px;
    }

    .looking-sec .looking-row .left-img-col {
        width: 100%;
        padding: 0;
    }

    .looking-sec .looking-row {
        margin: 0;
        margin-bottom: 60px;
    }

    .looking-bottom-row {
        padding-left: 0;
    }

    .looking-bottom-row .bottom-left-img-col {
        width: 100%;
        position: relative;
    }

    .looking-bottom-row .bottom-right-content {
        width: 100%;
        padding-top: 40px;
        padding-left: 0;
    }

    .looking-bottom-row .bottom-right-content ul {
        padding-left: 15px;
    }

    .looking-sec {
        padding: 27px 0 59px;
    }

    .counter-sec .counter-container .counter,
    .counter-sec .counter-container .countert-icon {
        font-size: 28px;
        line-height: 33px;
    }

    .sell-property-sec .sell-property-title {
        text-align: center;
        margin-bottom: 50px;
    }

    .sell-property-sec .sell-form-col .form-col-left {
        width: 100%;
        padding: 27px 20px 26px 20px;
    }

    .sell-property-sec .sell-form-col .form-img-right {
        width: 100%;
    }

    .sell-property-sec .sell-form-col {
        flex-direction: column-reverse;
        gap: 40px;
    }

    .looking-bottom-row .bottom-left-img-col::after,
    .looking-sec .looking-row .left-img-col::after {
        display: none;
    }

    .sell-property-sec .contact-form .submit-row .btn {
        width: 100%;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .video-repeater-col .video-repeater-3d-sec iframe {
        width: 100%;
        height: 474px;
    }

    .counter-sec .counter-container {
        display: flex;
        justify-content: space-between;
        text-align: center;
        max-width: 1146px;
        margin: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .counter-sec .counter-container .counter-box {
        padding: 25px 20px 25px;
        border-left: 0;
        border-top: 1px solid #A89A80;
        width: 100% !important;
    }

    .counter-sec .counter-container .counter-box:first-child {
        border-top: 0;
    }

    .repeater-img-slider .owl-nav button {
        width: 16px;
        height: 21px;
    }
    
    .repeater-img-slider .owl-nav button.owl-prev {
        left: 15px;
    }
    
    .repeater-img-slider .owl-nav button.owl-next {
        right: 15px;
    }


}

@media (max-width:600px) {
    body .filter-section.exclusives-filter .filter-form>div.price-column {
        border-top: 0;
        border-left: 1px solid rgba(0, 0, 0, 0.7);
    }

    body .filter-section.exclusives-filter .filter-form>.beds-column {
        width: 100%;
    }
}

@media (max-width:480px) {
    .form-title-row .form-title-right ul {
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
    }

    .form-title-row .form-title-right ul li {
        border-right: 0px solid #DEDEDE;
    }

    .details-row li span span {
        font-size: 14px;
        line-height: normal;
        line-height: 20px;
    }

    .details-row li span {
        font-size: 18px;
        line-height: 25px;

    }

    .details-row li {
        width: calc(100% / 1);
        padding: 14px 18px 14px !important;
        border: 1px solid #EEEEEE !important;
        border-top: 0px !important;
    }

    .details-row li:nth-child(1) {
        border-top: 1px solid #EEEEEE !important;

    }

    .looking-bottom-row .bottom-right-content ul {
        column-count: 1;
    }

    .looking-bottom-row .bottom-right-content ul li {
        margin-bottom: 15px;
    }

    .video-repeater-col .video-repeater-3d-sec iframe {
        width: 100%;
        height: 354px;
    }
}

.toast-container {
    position: fixed !important;
    /* Make it fixed so it doesn't scroll */
    top: 50% !important;
    /* Position it at the vertical center of the page */
    left: 50% !important;
    /* Position it horizontally at the center */
    transform: translate(-50%, -50%) !important;
    /* Adjust the position to truly center it */
    z-index: 9999 !important;
    /* Ensure it is above other content (like the sticky header) */
}

/*   header.main-header-sec {
    background-color: black;
} */

#sync3 img {
    max-height: 600px;
    object-fit: cover;
}