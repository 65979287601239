/* header.css */
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&family=Instrument+Sans:ital,wght@0,400..700;1,400..700&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

img {
    max-width: 100%;
    vertical-align: top;
}

header.main-header-sec {
    padding: 20px 35px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.header-mid .logo {
    width: 48px;
    margin: 0 auto;
}

.header-mid {
    width: 20%;
    text-align: center;
}

.header-right,
.header-menu-sec {
    width: 40%;
}

.header-menu-sec ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.header-right ul {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.header-right ul li,
.header-menu-sec ul li {
    list-style: none;
}

.header-menu-sec ul li {
    margin-right: 13px;
}

.header-right ul li a,
.header-menu-sec ul li a {
    color: #fff;
    font-size: 15px;
    font-family: "Instrument Sans", sans-serif;
    text-decoration: none;
    letter-spacing: normal;
    font-weight: 500;
    margin-bottom: 5px;
}

.header-menu-sec ul li a i.fas.fa-chevron-down {
    font-size: 12px;
    width: 19.05px;
    text-align: center;
}

.header-right ul li a i {
    font-size: 18px;
}

.header-right ul li {
    margin-left: 25px;
}

.menu-blank,
.mobile-toggle-btn,
.mobile-toggle-close {
    display: none;
}

.main-sec {
    padding: 40px 0 30px;
}

.total-results p {
    font-size: 14px;
}

.total-results p {
    font-size: 14px;
    margin-bottom: 0;
}

.scroll-up header.main-header-sec {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99999;
    background: #000 !important;
    -webkit-box-shadow: 0 0 20px 3px rgb(0 0 0 / 5%);
    box-shadow: 0 0 20px 3px rgb(0 0 0 / 5%);
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    -moz-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.banner-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /* background-size: 141%;
    background-position: 50%; */
}

.banner-header h1 {
   /* font-size: 58px !important;*/
}

@media (max-width: 1199px) {
    .menu-outer-sec {
        position: fixed;
        top: 0;
        left: -350px;
        height: 100%;
        overflow: auto;
        width: 80%;
        max-width: 280px;
        background: rgba(0, 0, 0, 0.9);
        box-shadow: 0 10px 30px 0 rgba(255, 165, 0, 0);
        z-index: 3;
        transition: all 0.35s ease-in-out;
    }

    .menu-blank {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: -100%;
        display: block;
        background: rgba(51, 51, 51, .5);
        transition: all 0.35s ease-in-out;
    }

    .menu-show .menu-outer-sec,
    .menu-show .menu-blank {
        left: 0;
    }

    .mobile-toggle-btn {
        display: block;
        width: 30px;
        cursor: pointer;
    }

    .header-menu-sec ul li {
        width: 100%;
        padding: 0 15px;
        text-align: left;
    }

    .header-menu-sec ul {
        flex-wrap: wrap;
    }

    .header-menu-sec ul li a {
        font-size: 16px;
        color: #E0E0E0;
        padding: 10px 0px 10px 0px;
        display: block;
    }

    .mobile-toggle-btn i {
        color: #fff;
        font-size: 24px;
    }

    .mobile-toggle-close {
        padding: 0;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 32px 15px 12px auto;
    }

    .mobile-toggle-close i {
        color: #fff;
        font-size: 18px;
    }

    .header-right ul li a i {
        font-size: 24px;
    }
}
@media (max-width: 1024px) {
    
}
@media (max-width: 991px) {

    .header-right ul li:not(.phone-btn) {
        display: none;
    }

    .header-menu-sec ul li.login {
        display: block !important;
    }

    .filter-section .filter-form>div.area-column.yield-area {
        width: 100%;
        border-left: 0;
    }
}

@media (max-width: 767px) {

    .insight-repeater-sec h2 {
        font-size: 28px;
    }

    header.main-header-sec {
        padding: 20px 15px;
    }

    .header-right ul li {
        margin: 0;
    }

   

    .banner-header {
        padding: 0% 20px;
        min-height: 358px;
    }

    .header-mid .logo {
        width: 41px;
    }


}

.header-menu-sec ul li span {
    color: #fff;
}