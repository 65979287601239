
* {
    margin: 0;
    padding: 0;
    box-sizing: 0;
    text-decoration: none;
    font-family: "Instrument Sans", Sans-serif;
}



ul,
ul li {
    padding: 0;
    margin: 0;
    list-style: none;
}

img,
iframe,
video {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.footer {
    padding: 65px 0px 0px 0px;
    background-color: #fff;
}

.footer .container {
    padding: 10px;
    max-width: 1400px;
    margin: auto;

}

.footer h4 {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
}

.footer-top-sec {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

}

.footer-top-sec .left-menu h4,
.footer-top-sec .right-menu h4 {
    margin-bottom: 20px;
}

.footer-top-sec>div {
    width: calc(100%/3);
}

.footer-top-sec .right-menu {
    text-align: right;
}

.footer-top-sec .center-logo {
    text-align: center;
    margin-top: 40px;
}

.center-logo a {
    max-width: 180px;
    display: inline-block;
    margin: auto;
}

.footer-top-sec ul li a {
    font-size: 14px;
    line-height: 2em;
    letter-spacing: 1px;
    color: #000;
}

.footer-top-sec .right-menu ul li {
    display: flex;
    align-items: baseline;
    justify-content: end;
    gap: 5px;
}

.footer-top-sec .right-menu ul li p {
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 2em;
}

.footer-top-sec .right-menu ul li a {
    text-decoration: underline;
    letter-spacing: 1px;
    color: #303339;

}

.footer-bottom {
    margin-top: 70px;
    margin-bottom: 40px;
}

.footer-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.right-socail-icon ul {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.right-socail-icon ul li a {
    color: #000;
    font-size: 21px;
}

.footer-bottom .left-text p {
    margin: 0;
    font-family: "Instrument Sans", Sans-serif;
    letter-spacing: 1px;
}
.footer-top-sec>div.left-menu{
    text-align: left;
}
.mobile-fixed{
    display: none;
}



@media (max-width: 991px) {
    
}


@media (max-width:767px) {

    .footer-top-sec>div {
        width: 100%;
        text-align: center;
    }

    .footer-top-sec .center-logo {
        display: none;
    }

    .footer-top-sec .right-menu {
        text-align: center;
        margin-top: 30px;
    }

    .footer-top-sec .right-menu ul li {
        display: flex;
        align-items: baseline;
        justify-content: center;
        gap: 5px;
    }

    .footer-bottom>div {
        width: 100%;
        text-align: center;
    }

    .footer-bottom {

        margin-top: 90px;
    }

    .footer-bottom .left-text p {
        font-size: 14px;
    }
    

    .right-socail-icon ul {
        justify-content: center;
    }

    .footer {
        padding:40px 0px 60px 0px;
        background-color: #fff;
    }
    .footer-top-sec>div.left-menu {
    text-align: center;
}
.right-socail-icon {
    order: 1;
    margin: 0 0 50px;
}
.footer-bottom>div.left-text {
    order: 2;
}
    .footer-bottom {
        margin-top: 40px;
    }
.mobile-fixed{
    display: block;
            position: fixed;
        bottom: 25px;
        left: 0;
        right: 0;
        margin: 0 !important;
        z-index: 999;
    }
    .mobile-fixed ul{
        padding: 13px 30px 13px 30px;
        background-color: #000;
        margin: 0 auto;
        max-width: 260px;
        justify-content: space-between;
}
.right-socail-icon.mobile-fixed ul li a {
    color: #fff;
}
}





/* General container for the filters */

.main-sec .container .header-section .sort-by {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
}

.header-section .sort-by label {
    color: rgba(0, 0, 0, 0.7);
    font-size: 14px;
    margin-right: 7px;
}

.header-section .sort-by select {
    background: none;
    border: solid 1px #000;
    padding: 7px 0 8px 10px;
    border-radius: 0;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    line-height: normal;
    display: inline-block;
    vertical-align: top;
    font-family: "Instrument Sans", sans-serif;
}

.filter-section  .filter-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border: 1px solid rgba(0, 0, 0, 0.7);
        position: relative;
        margin-bottom: 50px;
  }
  
  /* Each filter column (location, type, price, etc.) */
  .filter-section  .filter-form > div {
    width: calc(75% / 5);
    border-left: 1px solid rgba(0, 0, 0, 0.7);
    position: relative;
}
.filter-section  .filter-form > div > div.select-details {
    opacity: 0;
    visibility: hidden;
    z-index: -99;
    transition: all 0.15s ease-in-out;
}
.filter-section  .filter-form > div:hover > div.select-details {
    opacity: 1;
    visibility: visible;
    z-index: 99;
}
.filter-section  .filter-form .location-column {
    width: 25%;
    border-left: 0;
}
.filter-section  .filter-form > div > div:nth-child(2){
    position: absolute;
    top: 100%;
    left: 0px;
    z-index: 999999;
    background: #fff;
    max-height: 300px;
    overflow: auto;
    padding: 15px 10px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    width: 100%;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.filter-section  .filter-form > div > div:nth-child(2) ul {
    text-align: start !important;
}
.filter-section  .filter-form > div > div:nth-child(1) ul li{
    line-height: normal;
    padding: 5px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.filter-section  .filter-form > div > div:nth-child(1) ul li > span{  
    font-family: "Instrument Sans", sans-serif;
    font-size: 14px;
    line-height: 17px;
    width: calc(100% - 23px);
}
.filter-section  .filter-form > div > div:nth-child(2) > span{  
    display: block;
    text-align: center;
    font-size: 14px;
    line-height: normal;
    margin: 0;
}
.filter-section  .filter-form > div > div:nth-child(1) > input{
    width: 100%;
    height: 40px;
    background: none;
    border: none;
    box-shadow: none;
    padding: 0 23px 0 10px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: normal;
    font-family: "Instrument Sans", sans-serif;
}
.filter-section  .filter-form > div > div:nth-child(2) > input:not([type="checkbox"]){
    width: 100%;
    font-size: 15px;
    border: solid 1px #c1bebe;
    padding: 8px 10px;
    display: block;
    margin: 0;
    text-align: center;
}
.filter-section  .filter-form > div > div:nth-child(2) ul li input[type="checkbox"] {
    margin: 0 8px 0 0 !important;
}
.filter-section  .filter-form > div > div:nth-child(2) ul li ul {
    margin-left: 20px;
}

.filter-section  .filter-form > div > div:nth-child(1) {
    height: 40px;
    position: relative;
}
.filter-section  .filter-form > div.loader {
    position: absolute;
    top: 100%;
    left: 50%;
    border: none;
    width: auto;
    margin: 10px 0 0;
    transform: translateX(-50%);
}
.filter-section .filter-form{
-webkit-flex-wrap: nowrap;
-moz-flex-wrap: nowrap;
-ms-flex-wrap: nowrap;
flex-wrap: nowrap;
}

.filter-section .searchprop button .Searchbtn {
    width: 100%;
    border: 1px solid #000;
    padding: 10px 15px;
    font-family: "Instrument Sans", sans-serif;
    cursor: pointer;
}


.filter-section form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
}

/* Each filter column (location, type, price, etc.) */
.filter-section form > div {
width: calc(75% / 5);
border-left: 1px solid rgba(0, 0, 0, 0.7);
position: relative;
}
.filter-section form > div > div.select-details {
opacity: 0;
visibility: hidden;
z-index: -99;
transition: all 0.15s ease-in-out;
}
.filter-section form > div:hover > div.select-details {
opacity: 1;
visibility: visible;
z-index: 99;
}
.filter-section form .location-column {
width: 25%;
border-left: 0;
}
.filter-section form > div > div:nth-child(2){
position: absolute;
top: 100%;
left: 0px;
z-index: 999999;
background: #fff;
max-height: 300px;
overflow: auto;
padding: 15px 10px;
border: 1px solid rgba(0, 0, 0, 0.5);
width: 100%;
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.filter-section form > div > div:nth-child(2) ul {
text-align: start !important;
}
.filter-section form > div > div:nth-child(1) ul li{
line-height: normal;
padding: 5px 0;
display: flex;
justify-content: flex-start;
align-items: center;
flex-wrap: wrap;
}
.filter-section form > div > div:nth-child(1) ul li > span{  
font-family: "Instrument Sans", sans-serif;
font-size: 14px;
line-height: 17px;
width: calc(100% - 23px);
}
.filter-section form > div > div:nth-child(2) > span{  
display: block;
text-align: center;
font-size: 14px;
line-height: normal;
margin: 0;
}
.filter-section form > div > div:nth-child(1) > input{
width: 100%;
height: 40px;
background: none;
border: none;
box-shadow: none;
padding: 0 23px 0 10px;
font-size: 14px;
color: rgba(0, 0, 0, 0.8);
font-weight: normal;
font-family: "Instrument Sans", sans-serif;
}
.filter-section form > div > div:nth-child(2) > input:not([type="checkbox"]){
width: 100%;
font-size: 15px;
border: solid 1px #c1bebe;
padding: 8px 10px;
display: block;
margin: 0;
text-align: center;
}
.filter-section form > div > div:nth-child(2) ul li input[type="checkbox"] {
margin: 0 8px 0 0 !important;
}
.filter-section form > div > div:nth-child(2) ul li ul {
margin-left: 20px;
}

.filter-section form > div > div:nth-child(1) {
height: 40px;
position: relative;
}
.filter-section form > div.loader {
position: absolute;
top: 100%;
left: 50%;
border: none;
width: auto;
margin: 10px 0 0;
transform: translateX(-50%);
}
.filter-section form{
-webkit-flex-wrap: nowrap;
-moz-flex-wrap: nowrap;
-ms-flex-wrap: nowrap;
flex-wrap: nowrap;
}

.filter-sec form > .searchprop {
    width: calc(75% / 6);
}
.filter-section form .searchprop button.Searchbtn {
    width: 100%;
    border: 1px solid #000;
    padding: 11px 15px;
    font-family: "Instrument Sans", sans-serif;
    background-color: #000;
    cursor: pointer;
    color: #fff;
}
.filter-section form .searchprop button.Searchbtn:hover{
    background-color: transparent;
    color: #000;
}

.header-section ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.header-section ul li{
    display: block;
    border: solid 1px #000;
    text-transform: uppercase;
    color: #000;
    cursor: pointer;
    padding: 6px 15px 5px;
}
.header-section ul li.active {
    background: #000;
    color: #fff;
}
.main-sec h5 {
    text-align: left;
    margin-bottom: 20px;
}
.filter-section form.filter-form > div {
    width: calc(75% / 6);
}
.main-sec .container > h5 a {
    word-break: break-all;
}
.main-sec .container > h5 {    
    text-align: center;
}
.exclusives-filter .filter-form .location-column {
    width: 33.333%;
}

.exclusives-filter .filter-form .price-column {
    width: 33.333%;
}

.exclusives-filter .filter-form .beds-column {
    width: 33.333%;
}

  /* Responsive design */
@media (max-width: 991px) {
.filter-section  .filter-form {
    flex-wrap: wrap;
}
.filter-section  form.filter-form div.location-column, body .filter-section  form.filter-form > div {
        width: calc(100% / 3);
    }
.filter-section form.filter-form > div.searchprop {
    width: 100%;
    border-left: 0;
}
}
  @media (max-width: 767px) {

  }

@media only screen and (max-width: 600px) {
.filter-section  form.filter-form div.location-column, body .filter-section  form.filter-form > div,
.filter-section  .filter-form div.location-column, body .filter-section  .filter-form > div {
    width: calc(100% / 2);
}
body .filter-section  .filter-form > div.price-column {
    border-left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.7);
}
    .filter-section  .filter-form > div.condition-column {
        border-left: 1px solid rgba(0, 0, 0, 0.7);
    }
    .filter-section  .filter-form > div.beds-column {
        border-left: 0;
    }
        .filter-section  .filter-form > div.beds-column, .filter-section  .filter-form > div.area-column {
        border-top: 1px solid rgba(0, 0, 0, 0.7);
    }

    .exclusives-filter .filter-form .location-column {
        width: 50%;
    }
    
    .exclusives-filter .filter-form .price-column {
        width: 50%;
    }
    
    .exclusives-filter .filter-form .beds-column {
        width: 100%;
    }

}


